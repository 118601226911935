import React, { useEffect, useState } from 'react';
import * as echarts from 'echarts';
import { Box, Card, CardContent, Typography, Divider, Stack, Grid, Paper } from '@mui/material';
import { fetchHistogramData } from '../../api/HistogramAPI';
import { useTheme } from '@mui/material';

const HVACPlantStatus = (props) => {
  const [chartData, setChartData] = useState(null);
  const theme = useTheme();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const toEpoch = Math.floor(Date.now() / 1000);
        let fromEpoch;

        if (props.timeInterval === 'weekly') {
          fromEpoch = toEpoch - 7 * 24 * 60 * 60;
        } else if (props.timeInterval === 'daily') {
          fromEpoch = toEpoch - 24 * 60 * 60;
        } else if (props.timeInterval === 'monthly') {
          fromEpoch = toEpoch - 30 * 24 * 60 * 60;
        }

        const clients = [
          'source_panel_1_108_phase_r',
          'source_panel_1_108_phase_y',
          'source_panel_1_108_phase_b',
        ];

        const response = await fetchHistogramData({
          clientIds: clients,
          fromEpoch,
          toEpoch,
          origin: 'electrical_distribution',
          metric: 'power',
        });

        setChartData(response);
      } catch (error) {
        console.error('Error fetching HVAC data:', error);
      }
    };

    fetchData();
  }, [props.timeInterval]);

  useEffect(() => {
    if (chartData) {
      const chartDom = document.getElementById('energyConsumptionChart');
      const myChart = echarts.init(chartDom);

      const { timestamps, values } = chartData;

      const summedData = timestamps.map((timestamp, index) => {
        return (
          (values['source_panel_1_108_phase_r'][index] || 0) +
          (values['source_panel_1_108_phase_y'][index] || 0) +
          (values['source_panel_1_108_phase_b'][index] || 0)
        );
      });

      const option = {
        title: {
          text: 'HVAC Plant Power Consumption',
          left: 'center',
          textStyle: {
            fontSize: 18,
            fontWeight: 'bold',
            color: theme.palette.text.primary,
          },
        },
        tooltip: {
          trigger: 'axis',
          axisPointer: { type: 'shadow' },
          formatter: (params) => {
            const time = `<b>${params[0].axisValue}</b>`;
            const dataPoints = params
              .map((p) => `Power: ${p.value.toLocaleString()} kW`)
              .join('<br/>');
            return `${time}<br/>${dataPoints}`;
          },
          textStyle: {
            color: theme.palette.text.primary,
          },
        },
        grid: {
          left: '5%',
          right: '5%',
          bottom: '10%',
          containLabel: true,
        },
        xAxis: {
          type: 'category',
          data: timestamps.map((ts) => new Date(ts * 1000).toLocaleString()),
          boundaryGap: false,
          axisLine: { lineStyle: { color: '#aaa' } },
          axisLabel: {
            color: theme.palette.text.primary,
          },
        },
        yAxis: {
          type: 'value',
          axisLabel: {
            formatter: '{value} kW',
            color: theme.palette.text.primary,
          },
          axisLine: { lineStyle: { color: '#aaa' } },
        },
        series: [
          {
            data: summedData,
            type: 'line',
            smooth: true,
            areaStyle: {
              color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                { offset: 0, color: 'rgba(135, 206, 250, 0.7)' },
                { offset: 1, color: 'rgba(135, 206, 250, 0.1)' },
              ]),
            },
            lineStyle: {
              color: '#4682B4',
              width: 2,
            },
            itemStyle: {
              color: '#4682B4',
            },
          },
        ],
      };

      myChart.setOption(option);

      setTimeout(() => {
        myChart.resize();
      }, 100);
    }
  }, [chartData, theme]);

  useEffect(() => {
    const ahusChartDom = document.getElementById('ahusRunningChart');
    const ahusChart = echarts.init(ahusChartDom);

    const gaugeData = [
      {
        value: 23,
        detail: {
          valueAnimation: true,
          offsetCenter: ['0%', '0%'],
        },
      },
    ];

    const ahusOption = {
      title: {
        text: 'AHUs Running',
        left: 'center',
        textStyle: {
          fontSize: 16,
          fontWeight: 'bold',
          color: theme.palette.text.primary,
        },
      },
      series: [
        {
          type: 'gauge',
          startAngle: 90,
          endAngle: -270,
          radius: '75%', // Decreased the radius
          max: 42,
          pointer: { show: false },
          progress: {
            show: true,
            overlap: false,
            roundCap: true,
            clip: false,
            itemStyle: {
              borderWidth: 1,
              borderColor: '#464646',
            },
          },
          axisLine: { lineStyle: { width: 25 } },
          splitLine: { show: false, distance: 0, length: 10 },
          axisTick: { show: false },
          axisLabel: { show: false, distance: 50 },
          data: gaugeData,
          title: { fontSize: 14 },
          detail: {
            width: 50,
            height: 14,
            fontSize: 20,
            color: 'inherit',
            formatter: '{value}',
          },
        },
      ],
    };

    ahusChart.setOption(ahusOption);
  }, [theme]);

  return (
    <Box sx={{ flex: 3 }}>
      <Card
        sx={{
          borderRadius: 2,
          justifyContent: 'center',
          p: '5px',
          height: '390px',
          my: '5px',
        }}
        variant="outlined"
      >
        <CardContent>
          <Grid container spacing={2} sx={{  }}>
            <Grid item xs={12} md={8}>
              <Paper
                sx={{
                  p: 2,
                  borderRadius: 2,
                  border: '1px solid rgba(0, 0, 0, 0.1)',
                }}
                elevation={0}
              >
                <div id="energyConsumptionChart" style={{ width: '100%', height: '300px' }}></div>
              </Paper>
            </Grid>
            <Grid item xs={12} md={4}>
              <Paper
                sx={{
                  p: 2,
                  borderRadius: 2,
                  border: '1px solid rgba(0, 0, 0, 0.1)',
                }}
                elevation={0}
              >
                <div id="ahusRunningChart" style={{ width: '100%', height: '300px' }}></div>
              </Paper>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </Box>
  );
};

export default HVACPlantStatus;
