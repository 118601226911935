import React from 'react';
import { Chip } from '@mui/material';

const AlertChip = ({ label, type }) => {
  const getColor = () => {
    switch (type) {
      case 'critical': return 'error';
      case 'warning': return 'warning';
      case 'info': return 'info';
      case 'resolved': return 'success';
      case 'active': return 'error';
      default: return 'default';
    }
  };

  return <Chip label={label} color={getColor()} size="small" />;
};

export default AlertChip;
