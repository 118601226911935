import React, { useEffect, useRef, useState } from 'react';
import * as echarts from 'echarts';
import { Box, Card, Typography, CircularProgress } from '@mui/material';
import axios from 'axios';
import { powerAddress } from '../../Constants';
import { useTheme } from '@mui/material/styles';

const PowerConsumptionChart = (props) => {
  const chartRef = useRef(null);
  const [chartData, setChartData] = useState(null);
  const [loading, setLoading] = useState(true);
  const theme = useTheme();

  const getTimeRange = (interval) => {
    const now = Date.now();
    let fromEpoch;
    let toEpoch = Math.floor(now / 1000); // current timestamp in seconds

    if (interval === 'daily') {
      fromEpoch = Math.floor((now - 24 * 60 * 60 * 1000) / 1000); // last 24 hours
    } else if (interval === 'weekly') {
      fromEpoch = Math.floor((now - 7 * 24 * 60 * 60 * 1000) / 1000); // last 7 days
    } else if (interval === 'monthly') {
      fromEpoch = Math.floor((now - 30 * 24 * 60 * 60 * 1000) / 1000); // last 30 days
    }

    return { fromEpoch, toEpoch };
  };

  useEffect(() => {
    const { fromEpoch, toEpoch } = getTimeRange(props.timeInterval);

    const fetchData = async () => {
      setLoading(true); // Set loading state to true
      try {
        const response = await axios.post(powerAddress, {
          client_id: [
            "source_panel_1_101_phase_r", "source_panel_1_101_phase_y", "source_panel_1_101_phase_b",
            "source_panel_1_102_phase_r", "source_panel_1_102_phase_y", "source_panel_1_102_phase_b",
            "source_panel_1_103_phase_r", "source_panel_1_103_phase_y", "source_panel_1_103_phase_b"
          ],
          from_epoch: fromEpoch,
          to_epoch: toEpoch,
          origin: "electrical_distribution",
          metric: "power"
        });

        const { timestamps, values } = response.data;

        // Calculating the energy values for solar, dg, and grid
        const solar = timestamps.map((_, idx) => {
          return parseFloat((
            values["source_panel_1_101_phase_r"][idx] +
            values["source_panel_1_101_phase_y"][idx] +
            values["source_panel_1_101_phase_b"][idx]
          ).toFixed(2));
        });

        // Get max solar value to set the solar health
        if (props.timeInterval === 'weekly') {
          const maxSolar = Math.max(...solar);
          props.setSolarHealth(maxSolar);
        }

        const dg = timestamps.map((_, idx) => {
          return parseFloat((
            values["source_panel_1_102_phase_r"][idx] +
            values["source_panel_1_102_phase_y"][idx] +
            values["source_panel_1_102_phase_b"][idx]
          ).toFixed(2));
        });

        const grid = timestamps.map((_, idx) => {
          return parseFloat((
            values["source_panel_1_103_phase_r"][idx] +
            values["source_panel_1_103_phase_y"][idx] +
            values["source_panel_1_103_phase_b"][idx]
          ).toFixed(2));
        });

        setChartData({ timestamps, solar, dg, grid });
      } catch (error) {
        console.error('Error fetching chart data:', error);
      } finally {
        setLoading(false); // Set loading state to false
      }
    };

    fetchData();
  }, [props.timeInterval]);

  useEffect(() => {
    if (chartRef.current && chartData) {
      const myChart = echarts.init(chartRef.current);

      const option = {
        title: {
          text: 'Consumption Pattern',
          left: 'center',
          textStyle: {
            color: theme.palette.text.primary,
            fontWeight: '600',
          }
        },
        tooltip: {
          trigger: 'axis',
          backgroundColor: theme.palette.background.paper,
          axisPointer: {
            type: 'cross',
            label: {
              backgroundColor: '#6a7985'
            }
          },
          textStyle: {
            color: theme.palette.text.primary,
            fontSize: 14,
          },
          formatter: (params) => {
            const time = `<b>${params[0].axisValueLabel}</b>`;
          
            const tooltipContent = params.map(item => {
              const unit = 'kW';
              return `${item.seriesName}: ${item.value} ${unit}`;
            }).join('<br/>');
          
            const totalConsumption = params.reduce((total, item) => {
              return total + item.value;
            }, 0);
          
            return `${time}<br/>${tooltipContent}<br/><b>Total Consumption: ${totalConsumption.toFixed(2)} kW</b>`;
          }
        },
        legend: {
          data: ['Solar', 'Diesel Generator', 'Grid'],
          top: 'bottom',
          textStyle: {
            fontSize: 14,
            color: theme.palette.text.primary,
          },
        },
        xAxis: {
          type: 'category',
          boundaryGap: false,
          data: chartData.timestamps.map(timestamp => {
            const date = new Date(timestamp * 1000); // Convert to milliseconds
            const dayOfWeek = date.toLocaleDateString('en-US', { weekday: 'short' }); // Short day (e.g., Mon)
            const datePart = date.toLocaleDateString('en-US', { month: '2-digit', day: '2-digit' }); // e.g., 12/17
            const timePart = date.toLocaleTimeString('en-US', { hour: '2-digit', minute: '2-digit' }); // e.g., 08:30 AM
            return `${dayOfWeek} ${datePart} \n${timePart}`;
          }),
          axisLabel: {
            formatter: function (value) {
              return value.split('\n').join('\n'); // Ensure the label breaks into two lines
            },
            rich: {
              'line': {
                lineHeight: 20,
                align: 'center',
              }
            },
            textStyle: {
              fontSize: 10,
              color: theme.palette.text.primary,
            },
          }
        },
        yAxis: {
          type: 'value',
          axisLabel: {
            textStyle: {
              fontSize: 10,
              color: theme.palette.text.primary,
            }
          }
        },
        series: [
          {
            name: 'Solar',
            type: 'line',
            stack: 'Total',
            smooth: true,
            lineStyle: { width: 0 },
            showSymbol: false,
            areaStyle: {
              opacity: 0.8,
              color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                { offset: 0, color: 'rgb(128, 255, 165)' },
                { offset: 1, color: 'rgb(1, 191, 236)' }
              ])
            },
            data: chartData.solar
          },
          {
            name: 'Diesel Generator',
            type: 'line',
            stack: 'Total',
            smooth: true,
            lineStyle: { width: 0 },
            showSymbol: false,
            areaStyle: {
              opacity: 0.8,
              color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                { offset: 0, color: 'rgb(255, 0, 135)' },
                { offset: 1, color: 'rgb(135, 0, 157)' }
              ])
            },
            data: chartData.dg
          },
          {
            name: 'Grid',
            type: 'line',
            stack: 'Total',
            smooth: true,
            lineStyle: { width: 0 },
            showSymbol: false,
            areaStyle: {
              opacity: 0.8,
              color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                { offset: 0, color: 'rgb(55, 162, 255)' },
                { offset: 1, color: 'rgb(116, 21, 219)' }
              ])
            },
            data: chartData.grid
          },
        ]
      };

      myChart.setOption(option);
    }
  }, [chartData, theme]);

  return (
    <Box sx={{ width: '100%' }}>
      <Card sx={{ borderRadius: 2, width: '100%', height: { xs: '300px', md: '360px' }, padding: '16px' }} variant='outlined'>
        {loading ? (
          <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
            <CircularProgress size="3rem" />
          </Box>
        ) : (
          <Box sx={{ width: '100%', height: '100%' }} ref={chartRef}></Box>
        )}
      </Card>
    </Box>
  );
};

export default PowerConsumptionChart;
