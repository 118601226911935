import React, { useEffect, useState } from 'react';
import { Card, CardContent, Typography, Box, Divider, CircularProgress } from '@mui/material';
import axios from 'axios';
import { API_BASE_URL } from '../../config/Constants';

const Counter = ({ selectedClient, dateRange }) => {
  const [countersData, setCountersData] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fetchCountersData = async () => {
      if (!selectedClient || !dateRange) return;
  
      setLoading(true);
  
      try {
        const fromEpoch = Math.floor(dateRange[0].getTime() / 1000);
        const toEpoch = Math.floor(dateRange[1].getTime() / 1000);
  
        // Append '_phase_r' to client_id if it doesn't start with 'w'
        const clientId =
          selectedClient.client_id.startsWith('w')
            ? selectedClient.client_id
            : `${selectedClient.client_id}_phase_r`;
  
        const response = await axios.get(`${API_BASE_URL}counters/get_counters/`, {
          params: {
            client_id: clientId,
            client_type: selectedClient.client_type,
            from_epoch: fromEpoch,
            to_epoch: toEpoch,
          },
        });
  
        setCountersData(response.data);
      } catch (error) {
        console.error('Error fetching counters data:', error);
        setCountersData(null);
      } finally {
        setLoading(false);
      }
    };
  
    fetchCountersData();
  }, [selectedClient, dateRange]);
  

  if (!selectedClient) {
    return (
      <Card sx={{ p: 2, height: '52%', mb: 2 }}>
        <Typography variant="h6" align="center" color="text.secondary">
          No Client Selected
        </Typography>
      </Card>
    );
  }

  const { client_label, description } = selectedClient;

  return (
    <Card
      sx={{
        maxWidth: '320px',
        minWidth: '180px',
        p: '10px',
        mb: 2,
        height: '48%',
      }}
      variant="outlined"
    >
      <CardContent>
        {/* Client Name */}
        <Typography variant="h6" sx={{ fontWeight: 600, mb: 1 }}>
          {client_label}
        </Typography>

        {/* Client Details */}
        <Typography variant="body2" color="text.secondary" sx={{ mb: 2 }}>
          {description || 'No description available.'}
        </Typography>

        <Divider sx={{ mb: 2 }} />

        {/* Energy/Volume Consumption */}
        {loading ? (
          <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100px' }}>
            <CircularProgress />
          </Box>
        ) : countersData ? (
          <Box>
            <Box sx={{ mb: 2 }}>
              <Typography variant="subtitle2">Today:</Typography>
              <Typography variant="body1" sx={{ fontWeight: '500' }}>
                {countersData.today.value.toLocaleString()} {countersData.today.unit}
              </Typography>
            </Box>
            <Box sx={{ mb: 2 }}>
              <Typography variant="subtitle2">This Month:</Typography>
              <Typography variant="body1" sx={{ fontWeight: '500' }}>
                {countersData.month.value.toLocaleString()} {countersData.month.unit}
              </Typography>
            </Box>
            <Box>
              <Typography variant="subtitle2">Interval:</Typography>
              <Typography variant="body1" sx={{ fontWeight: '500' }}>
                {countersData.interval.value.toLocaleString()} {countersData.interval.unit}
              </Typography>
            </Box>
          </Box>
        ) : (
          <Typography variant="body2" color="text.secondary">
            No data available.
          </Typography>
        )}
      </CardContent>
    </Card>
  );
};

export default Counter;
