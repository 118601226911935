import React from "react";
import { GoogleOAuthProvider } from "@react-oauth/google";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import UserAuthentication from "./auth/UserLogin";
import { BrowserRouter as Router, Routes, Route, Navigate } from "react-router-dom";
import ProtectedRoute from "./auth/ProtectedRoute";
import { AlertsProvider } from "./context/AlertsContext";
import { ClientsProvider } from "./context/ClientsContext";

const root = ReactDOM.createRoot(document.getElementById("root"));

const clientId = "599218589490-77dgtcq5lhk8emibm3c4f5hpk8icjil5.apps.googleusercontent.com";

root.render(
  <ClientsProvider>
  <AlertsProvider>
  <GoogleOAuthProvider clientId={clientId}>
    <Router>
      <Routes>
        <Route element={<ProtectedRoute />}>
          <Route path="/*" exect element={<App />} />
        </Route>
        <Route path="/login" element={<UserAuthentication />} />
      </Routes>
    </Router>
  </GoogleOAuthProvider>
  </AlertsProvider>
  </ClientsProvider>
);
