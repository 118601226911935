import React, { useEffect, useRef } from 'react';
import * as echarts from 'echarts';
import { Box, Card, CardContent, Chip, Divider, Grid, Typography } from '@mui/material';
import { Done, Error, Warning } from '@mui/icons-material';
import { useTheme } from '@mui/material/styles';

const SolarPlantStatus = (props) => {
    const chartRef = useRef(null); // Ref for chart DOM node
    const chartInstance = useRef(null); // Ref to store ECharts instance
    const theme = useTheme(); // Access the current theme

    const solarColors = ['#dcdcdc', '#6a5acd'];

    // Use props.solarHealth to set the solarHealthData
    const solarHealthData = [
        { value: props.solarHealth || 0 } // Default to 0 if solarHealth is undefined or null
    ];

    // Plant health thresholds
    let healthStatus = 'Good';
    let chipColor = 'success';
    let chipIcon = <Done />;
    
    // Determine plant health based on solarHealth value
    if (props.solarHealth < 150) {
        healthStatus = 'Bad';
        chipColor = 'error';
        chipIcon = <Error />;
    } else if (props.solarHealth >= 150 && props.solarHealth <= 300) {
        healthStatus = 'Medium';
        chipColor = 'warning';
        chipIcon = <Warning />;
    }

    useEffect(() => {
        // Initialize the chart
        if (chartRef.current) {
            // Dispose the old chart instance if it exists
            if (chartInstance.current) {
                chartInstance.current.dispose();
            }

            // Create a new chart instance
            chartInstance.current = echarts.init(chartRef.current);

            // Chart options
            const option = {
                series: [
                    {
                        type: 'gauge',
                        radius: '90%',
                        max: 450,
                        splitNumber: 9,
                        axisLine: {
                            lineStyle: {
                                width: 30,
                                color: [
                                    [0.333, '#FF6962'], // Red for bad
                                    [0.667, '#F0CB00'], // Yellow for medium
                                    [1, '#5FA777']      // Green for good
                                ]
                            }
                        },
                        pointer: {
                            itemStyle: {
                                color: 'auto'
                            }
                        },
                        axisTick: {
                            distance: -30,
                            length: 6,
                            lineStyle: {
                                color: '#fff',
                                width: 2
                            }
                        },
                        splitLine: {
                            distance: -30,
                            length: 30,
                            lineStyle: {
                                color: '#fff',
                                width: 4
                            }
                        },
                        axisLabel: {
                            color: 'inherit',
                            distance: 40,
                            fontSize: 13,
                            color: theme.palette.text.primary
                        },
                        detail: {
                            valueAnimation: true,
                            formatter: '{value} kW',
                            offsetCenter: [0, '70%'],
                            color: theme.palette.text.primary,
                            fontSize: 18
                        },
                        data: solarHealthData,
                    }
                ],
                color: solarColors
            };

            chartInstance.current.setOption(option);
        }

        // Cleanup function to dispose of chart on unmount or re-render
        return () => {
            if (chartInstance.current) {
                chartInstance.current.dispose();
                chartInstance.current = null;
            }
        };
    }, [props.solarHealth, theme]); // Depend only on solarHealth for updates

    return (
        <Box sx={{ flex: 1 }}>
            <Card sx={{ borderRadius: 2, justifyContent: "center", p: "5px", height: "390px", my: '5px' }} variant='outlined'>
                <CardContent>
                    <Typography align="center" variant="h5" marginBottom={1.5} sx={{ fontWeight: '600'}}>
                        Solar Plant Status
                    </Typography>
                    <Divider />
                    <Box sx={{ height: '240px', mb: 0 }} ref={chartRef}></Box> {/* Chart will render here */}
                    <Box>
                        <Grid container>
                            <Grid item xs={12} md={6}>
                                <Typography align="center" variant="h6" sx={{ fontWeight: '500' }}>
                                    Plant Health: 
                                    <Chip icon={chipIcon} label={healthStatus} color={chipColor} size="small" />
                                </Typography>
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <Typography align="center" variant="h6" sx={{ fontWeight: '500' }}>
                                    Plant Capacity: 450kW
                                </Typography>
                            </Grid>
                        </Grid>
                    </Box>
                </CardContent>
            </Card>
        </Box>
    );
};

export default SolarPlantStatus;
