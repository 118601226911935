const chartUnits = {
    power: 'kW',
    voltage_rms: 'V',
    current_rms: 'A',
    voltage_frequency: 'Hz',
    total_power: 'kW',
    power_factor: '',
    volume_flow_rate: 'l/s',
    mass_flow_rate : 'g/s',
    flow_speed : 'km/h'
  };
  
  export default chartUnits;
  