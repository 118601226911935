import React, { createContext, useState, useEffect } from "react";
import { fetchAlerts, fetchAlertDetails } from "../api/AlertsAPI";

export const AlertsContext = createContext();

export const AlertsProvider = ({ children }) => {
  const [alerts, setAlerts] = useState([]);
  const [alertDetails, setAlertDetails] = useState(null);
  const [loading, setLoading] = useState(false);
  const [filters, setFilters] = useState({});
  const [pagination, setPagination] = useState({
    count: 0,
    next: null,
    previous: null,
  });

  /**
   * Fetch Alerts
   * Handles both initial and paginated fetch
   */
  const loadAlerts = async (url = null, append = false) => {
    setLoading(true);
    try {
      const data = await fetchAlerts(url, filters);

      // Avoid duplicates by using a Set for alert IDs
      setAlerts((prev) => {
        const existingIds = new Set(prev.map((alert) => alert.id));
        const newAlerts = data.results.filter((alert) => !existingIds.has(alert.id));
        return append ? [...prev, ...newAlerts] : newAlerts;
      });

      setPagination({
        count: data.count,
        next: data.next,
        previous: data.previous,
      });
    } catch (error) {
      console.error("Failed to fetch alerts:", error);
    } finally {
      setLoading(false);
    }
  };

  /**
   * Fetch Alert Details
   */
  const loadAlertDetails = async (alertId) => {
    setLoading(true);
    try {
      const data = await fetchAlertDetails(alertId);
      setAlertDetails(data);
    } catch (error) {
      console.error("Failed to fetch alert details:", error);
    } finally {
      setLoading(false);
    }
  };

  /**
   * Load the next page of alerts for infinite scroll
   */
  const loadNextAlerts = async () => {
    if (pagination.next && !loading) {
      await loadAlerts(pagination.next, true);
    }
  };

  // Refetch alerts when filters change
  useEffect(() => {
    loadAlerts();
  }, [filters]);

  // Fetch latest alerts every 2 minutes
  useEffect(() => {
    const intervalId = setInterval(() => {
      loadAlerts();
    }, 2 * 60 * 1000); // 2 minutes in milliseconds

    return () => clearInterval(intervalId); // Cleanup interval on component unmount
  }, [filters]);

  return (
    <AlertsContext.Provider
      value={{
        alerts,
        alertDetails,
        loading,
        filters,
        pagination,
        setFilters,
        loadAlerts,
        loadNextAlerts,
        loadAlertDetails,
      }}
    >
      {children}
    </AlertsContext.Provider>
  );
};
