import React, { useEffect, useState, useContext } from 'react';
import Box from '@mui/material/Box';
import ClientNav from '../../Components/ClientMonitor/ClientNav';
import ControlOptions from '../../Components/ClientMonitor/ControlOptions';
import LiveFigures from '../../Components/ClientMonitor/LiveFigures';
import CenterGraph from '../../Components/ClientMonitor/CenterGraph';
import { ClientsContext } from '../../context/ClientsContext';
import { fetchHistogramData } from '../../api/HistogramAPI';
import { useLocation } from 'react-router-dom';
import { electrcialMetrics } from '../../config/PlotConfig';
import Counter from '../../Components/ClientMonitor/Counter';

function Power(props) {
  const location = useLocation();
  const alertSource = location.state || {};

  const [graphType, setGraphType] = useState('line');
  const [plotType, setPlotType] = useState('power');
  const [selectedClient, setSelectedClient] = useState(null);
  const [chartData, setChartData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [dateRange, setDateRange] = useState([
    new Date(Date.now() - 7 * 24 * 60 * 60 * 1000),
    new Date(),
  ]);

  const { clients: allClients } = useContext(ClientsContext);

  const groupedClients = allClients
    .filter((client) => ['electrical_generation', 'electrical_distribution'].includes(client.client_type))
    .reduce((groups, client) => {
      if (!groups[client.block_id]) {
        groups[client.block_id] = {
          block_label: client.block_label || client.block_id,
          clients: [],
        };
      }
      groups[client.block_id].clients.push(client);
      return groups;
    }, {});

  const sortedGroups = Object.entries(groupedClients).sort(([a], [b]) => {
    if (a === 'gss') return -1;
    if (b === 'gss') return 1;
    return 0;
  });

  useEffect(() => {
    if (groupedClients['gss']) {
      const mainLine1Client = groupedClients['gss'].clients.find((client) =>
        client.client_label.toLowerCase().includes('main line 1')
      );
      setSelectedClient(mainLine1Client || groupedClients['gss'].clients[0]);
    }
  }, [allClients]);

  useEffect(() => {
    if (selectedClient) {
      const fetchData = async () => {
        setLoading(true);
        try {
          const fromEpoch = Math.floor(dateRange[0].getTime() / 1000);
          const toEpoch = Math.floor(dateRange[1].getTime() / 1000);

          const clientIds = selectedClient.phase.map((phase) => `${selectedClient.client_id}_${phase.id}`);

          const data = await fetchHistogramData({
            clientIds,
            fromEpoch,
            toEpoch,
            origin: selectedClient.client_type,
            metric: plotType === 'total_power' ? 'power' : plotType,
          });

          setChartData({
            timestamps: data.timestamps,
            ...selectedClient.phase.reduce((phaseData, phase) => {
              phaseData[phase.id] = data.values[Object.keys(data.values).find((key) => key.endsWith(phase.id))];
              return phaseData;
            }, {}),
          });
        } catch (error) {
          console.error('Error fetching chart data:', error);
        } finally {
          setLoading(false);
        }
      };

      fetchData();
    }
  }, [selectedClient, plotType, dateRange]);

  useEffect(() => {
    props.setNavIndex(1);
    if (alertSource.dateRange) setDateRange(alertSource.dateRange);
    if (alertSource.selectedClient) setSelectedClient(alertSource.selectedClient);
    if (alertSource.metric) setPlotType(alertSource.metric === 'voltage_frequency' ? 'voltage_rms' : alertSource.metric);
  }, []);

  return (
    <Box sx={{ display: 'flex', p: 1, height: `calc(100vh - 65px)`, overflow: 'hidden', pb: '10px' }}>
      <ClientNav groupedClients={Object.fromEntries(sortedGroups)} setSelectedClient={setSelectedClient} selectedClient={selectedClient} />
      <Box component="main" sx={{ flexGrow: 1, px: '5px', height: '100%', minHeight: '600px', overflow: 'scroll' }}>
        <LiveFigures selectedClient={selectedClient} />
        <CenterGraph selectedClient={selectedClient} chartData={chartData} loading={loading} graphType={graphType} plotType={plotType} setPlotType={setPlotType} setGraphType={setGraphType} setDateRange={setDateRange} dateRange={dateRange} />
      </Box>
      <Box
  sx={{
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
  }}
>
  {/* Client Details Card */}
  <Counter selectedClient={selectedClient} dateRange={dateRange} />

  {/* Control Options */}
  <ControlOptions
    graphType={graphType}
    plotType={plotType}
    setGraphType={setGraphType}
    setPlotType={setPlotType}
    drawerOpen={props.drawerOpen}
    metrices={electrcialMetrics}
  />
</Box>    </Box>
  );
}

export default Power;
