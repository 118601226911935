import React from 'react';
import { Card, Box, Typography, CircularProgress, Select, MenuItem, FormControl, InputLabel } from '@mui/material';
import LineChart from '../Charts/LineChart';
import BarChart from '../Charts/BarChart';
import { DateRangePicker } from 'rsuite';
import 'rsuite/dist/rsuite.css';
import { graphTypes } from '../../config/PlotConfig'; // Import graphTypes from PlotConfig

const CenterGraph = ({
  chartData,
  loading,
  graphType,
  plotType,
  setPlotType,
  setDateRange,
  dateRange,
  setGraphType,
}) => {
  const parseChartData = () => {
    if (!chartData?.timestamps?.length) {
      return { timestamps: [], phase_r: [], phase_y: [], phase_b: [] };
    }

    if (plotType === 'total_power') {
      const totalPower = chartData.phase_r.map(
        (val, index) =>
          (val || 0) +
          (chartData.phase_y?.[index] || 0) +
          (chartData.phase_b?.[index] || 0)
      );

      return {
        timestamps: chartData.timestamps,
        total_power: totalPower,
      };
    }
    return chartData;
  };

  const parsedData = parseChartData();

  return (
    <Card
      sx={{
        height: `calc(67% - 15px)`,
        minHeight: '490px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        pt: '15px',
      }}
      variant="outlined"
    >
      {/* Header */}
      <Box
        sx={{
          width: '100%',
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          marginBottom: '10px',
          px: '25px',
        }}
      >
        {/* Plot Type Dropdown */}
        <FormControl
          variant="outlined"
          size="small"
          sx={{
            width: '150px',
            '& .MuiSelect-root': {
              height: '25px', // Reduce the height of the dropdown
              lineHeight: '25px',
            },
            '& .MuiMenuItem-root': {
              minHeight: '25px', // Reduce the height of each dropdown item
            },
          }}
        >
          <InputLabel id="plot-type-label">Plot Type</InputLabel>
          <Select
            labelId="plot-type-label"
            value={graphType}
            onChange={(e) => setGraphType(e.target.value)}
            label="Plot Type"
          >
            {graphTypes.map((type) => (
              <MenuItem key={type.type} value={type.type}>
                <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                  {type.icon}
                  <Typography variant="body1">{type.name}</Typography>
                </Box>
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        {/* Date Range Picker */}
        <DateRangePicker
          value={dateRange}
          onChange={(value) =>
            setDateRange(
              value || [
                new Date(Date.now() - 7 * 24 * 60 * 60 * 1000),
                new Date(),
              ]
            )
          }
          style={{ maxWidth: '300px' }}
          placeholder="Select Date Range"
        />
      </Box>

      {/* Chart */}
      <Box sx={{ flexGrow: 1, width: '100%' }}>
        {loading ? (
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              height: '100%',
            }}
          >
            <CircularProgress />
          </Box>
        ) : graphType === 'line' ? (
          <LineChart
            chartData={parsedData}
            plotType={plotType}
            legendLabels={
              plotType === 'total_power'
                ? ['Total Power']
                : ['Phase R', 'Phase Y', 'Phase B']
            }
          />
        ) : (
          <BarChart
            chartData={parsedData}
            plotType={plotType}
            legendLabels={
              plotType === 'total_power'
                ? ['Total Power']
                : ['Phase R', 'Phase Y', 'Phase B']
            }
          />
        )}
      </Box>
    </Card>
  );
};

export default CenterGraph;
