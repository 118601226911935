import { Box, Breadcrumbs, Divider, FormControl, Grid, InputLabel, MenuItem, Select, Stack, ToggleButton, ToggleButtonGroup, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { overviewBackendAddress as backEnd} from "../../Constants"
import axios from "axios";
import { useNavigate } from "react-router-dom";
import TrendsCard from "../../Components/OverviewCards/TrendsCard";
import PowerFigures from "../../Components/OverviewCards/PowerFigures";
import WaterConsumptionChart from "../../Components/OverviewCards/WaterConsumptionChart";
import SystemAlerts from "../../Components/OverviewCards/SystemAlerts";
import HVACPlantStatus from "../../Components/OverviewCards/HVACPlantStatus";
import SolarPlantStatus from "../../Components/OverviewCards/SolarPlantStatus";
import SourcesDonut from "../../Components/OverviewCards/SourcesDonut";
import PowerConsumptionChart from "../../Components/OverviewCards/PowerConsumptionChart";

const waterDummyData = [{
    card_label: 'PHE',
    energy_unit: 'K.Ltr.',
    energy: 54,
    drift: 21
},
{
    card_label: 'Borewell',
    energy_unit: 'K.Ltr.',
    energy: 0,
    drift: -0
}
]

export default function Overview(props) {
    const [sourcesData, setSourcesData] = useState(0);
    const [totalConsumption, setTotalConsumption] = useState(0);
    const [timeInterval, setTimeInterval] = useState("weekly");
    const [sourcesEnergy, setSourcesEnergy] = useState(0);
    const [loading, setLoading] = useState(true); 
    const [solarHealth, setSolarHealth] = useState(null)
    const navigate = useNavigate();

    const handleIntervalChange = async (event, newTimeInterval) => {
        if (newTimeInterval != null) {
            setTimeInterval(newTimeInterval);
        }
    };

    const handleIntervalListChange = async (event) => {
        setTimeInterval(event.target.value);
    };

    useEffect(() => {
        props.setNavIndex(0);
        const getCardsData = async () => {
            setLoading(true); // Set loading to true when starting to fetch data
            await axios({
                url: backEnd,
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                data: JSON.stringify({
                    timespan: timeInterval,
                }),
            })
                .then((res) => {
                    setTotalConsumption(res.data.splice(3));
                    setSourcesData(res.data);
                    var normalizedSources = res.data;
                    for (let i = 0; i < res.data.length; i++) {
                        if (res.data[i].energy_unit === "MWh") {
                            normalizedSources[i].energy = res.data[i].energy * 1000;
                        }
                    }
                    setSourcesEnergy(normalizedSources);
                    setLoading(false); // Set loading to false once data is fetched
                })
                .catch(() => {
                    setLoading(false); // Handle error and set loading to false
                });
        }
        getCardsData();
    }, [timeInterval]);

    return (
        <Box component="main" sx={{ flexGrow: 1, p: 2 }}>
            <Grid container columns={{ xs: 12 }}>
                <Grid item xs={8}>
                    <Breadcrumbs aria-label="breadcrumb" marginBottom={2} marginTop={1}>
                        <Typography color='primary'>Home</Typography>
                        <Typography color='primary'>Overview</Typography>
                    </Breadcrumbs>
                </Grid>
                <Grid item xs={4} display={{ xs: 'none', md: "block" }}>
                    <Box justifyContent="flex-end" display={{ xs: 'none', md: "flex" }}>
                        <ToggleButtonGroup value={timeInterval} exclusive onChange={handleIntervalChange} aria-label="Interval" color="primary" size="small">
                            <ToggleButton value="daily">Daily</ToggleButton>
                            <ToggleButton value="weekly">Weekly</ToggleButton>
                            <ToggleButton value="monthly">Monthly</ToggleButton>
                        </ToggleButtonGroup>
                    </Box>
                </Grid>
                <Grid item xs={4} >
                    <Box justifyContent="flex-end" display={{ xs: 'flex', md: "none" }}>
                        <FormControl fullWidth>
                            <InputLabel>Interval</InputLabel>
                            <Select value={timeInterval} label="interval" onChange={handleIntervalListChange} size="small">
                                <MenuItem value="daily">Daily</MenuItem>
                                <MenuItem value="weekly">Weekly</MenuItem>
                                <MenuItem value="monthly">Monthly</MenuItem>
                            </Select>
                        </FormControl>
                    </Box>
                </Grid>
            </Grid>

            <Box sx={{ display: { xs: 'none', md: 'flex' } }}>
                <TrendsCard sourcesData={sourcesData[0]} loading={loading} />
                <TrendsCard sourcesData={sourcesData[1]} loading={loading} />
                <TrendsCard sourcesData={sourcesData[2]} loading={loading} />
                <TrendsCard sourcesData={sourcesData[3] ? sourcesData[3] : waterDummyData[0]} loading={loading} />
                <TrendsCard sourcesData={sourcesData[4] ? sourcesData[4] : waterDummyData[1]} loading={loading} />
            </Box>

            <Box sx={{ display: { xs: 'block', md: 'none' }, marginTop: "2vh" }}>
                <TrendsCard sourcesData={sourcesData[0]} loading={loading} />
                <TrendsCard sourcesData={sourcesData[1]} loading={loading} />
                <TrendsCard sourcesData={sourcesData[2]} loading={loading} />
                <TrendsCard sourcesData={totalConsumption[0]} loading={loading} />
            </Box>

            <Divider sx={{ mb: '8px' }} />
            <Box sx={{ display: 'flex', width: "100%", flexWrap: 'wrap', overflow: 'hidden', flexDirection: 'column', p: 1 }}>
                <Stack direction="row" spacing={1}>
                    <SourcesDonut sourcesData={sourcesData} sourcesEnergy={sourcesEnergy} loading ={loading} timeInterval={timeInterval}/>
                    <WaterConsumptionChart loading={loading} timeInterval={timeInterval}/>
                </Stack>
                <Stack direction="row" spacing={1} my={1}>
                    <SystemAlerts loading={loading}/>
                    <Box sx={{ display: 'flex', flexDirection: 'column', height: '100%', flex: 4 }}>
                        <PowerFigures />
                        <PowerConsumptionChart sourcesData={sourcesData} timeInterval = {timeInterval} setSolarHealth = {setSolarHealth}/>
                    </Box>
                </Stack>
                <Stack direction="row" spacing={1}>
                    <SolarPlantStatus mode={props.mode} solarHealth = {solarHealth} loading={loading}/>
                    <HVACPlantStatus timeInterval = {timeInterval}/>
                </Stack>
            </Box>
        </Box>
    )
}
