// API Base URLs
export const API_BASE_URL = "https://api.datasailors.io/iot-backend/api/v1/";

// Google OAuth
export const GOOGLE_CLIENT_ID = "599218589490-77dgtcq5lhk8emibm3c4f5hpk8icjil5.apps.googleusercontent.com";

// UI Configurations
export const DRAWER_WIDTH = "280px";
export const CLOSED_DRAWER_WIDTH = "75px";

// Default Theme Configurations
export const LIGHT_THEME = {
  background: {
    default: '#f0f2f5',
    paper: '#f0f2f5',
  },
};

export const DARK_THEME = {
  background: {
    default: '#0b141a',
    paper: '#100c2a',
  },
};
