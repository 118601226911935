import React, { Component, createContext } from 'react';
import './App.css';
import NavBar from './Components/NavBar/NavBar';
import Overview from './Pages/Overview/Overview';
import Water from './Pages/Water/Water';
import Analytics from './Pages/Analytics/Analytics';
import Help from './Pages/Help/Help';
import About from './Pages/About/About';
import {Routes, Route} from 'react-router-dom';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import { Box } from '@mui/material';
import Power from './Pages/Power/Power';
import Config from './Pages/Settings/Config';
import AlertsPage from './Pages/Alerts/AlertsPage';

const ColorModeContext = createContext({ toggleColorMode: () => {} });

const drawerWidth = '280px';
const closedDrawerWidth = '75px';

class appMain extends Component{
  constructor(props) {
    super(props);
    this.state={
      mode : 'light',
      userID:props.userID,
      sideNavIndex: 0,
      open : false,
    }
    this.toggleColorMode=this.toggleColorMode.bind(this);
    this.setNavIndex=this.setNavIndex.bind(this);
    this.drawerToggle=this.drawerToggle.bind(this);
  }
  
  toggleColorMode () {
    const newMode=(this.state.mode==="light" ? "dark" :"light");
    this.setState({mode:newMode});
  }
  setNavIndex (newIndex) {
    this.setState({sideNavIndex:newIndex});
  }
  drawerToggle () {
    const newOpen=(this.state.open ? false:true);
    this.setState({open:newOpen});
  };

  render(){ 
    const colorMode =({
        toggleColorMode: () => {
          const newMode=(this.state.mode==="light" ? "dark" :"light");
          this.setState({mode:newMode});
        },
      });

    const contentStyle = this.state.open
    ? { marginLeft: {sm: drawerWidth}, width: {sm: `calc(100%-${80}px`},}
    : { marginLeft: {sm: closedDrawerWidth}, width: {sm: `calc(100%-${80}px`},};
  
    const transitionStyle = {
      transition: 'all 0.8s ease-out'
    };

    const theme = 
        createTheme({
          palette: {
            mode:this.state.mode,
            ...(this.state.mode === 'light' && {
              background: {
                default: '#f0f2f5',
                paper: '#f0f2f5',
              },
            }),
            ...(this.state.mode === 'dark' && {
              background: {
                default: '#0b141a',
                paper: '#100c2a',
              },
            }),
          },
          components:{
            MuiToggleButton:{
              styleOverrides:{
                root:{
                  minWidth:"80px",
                  height:"30px"
                }
              }
            },
          },
        });

    return(
    <React.Fragment>
      <ColorModeContext.Provider value={colorMode}>
      <ThemeProvider theme={theme}>
      <CssBaseline />
        <NavBar changeTheme={this.toggleColorMode} mode={this.state.mode} navIndex={this.state.sideNavIndex} open={this.state.open} drawerToggle={this.drawerToggle}/>
        <Box sx={{ ...contentStyle, ...transitionStyle }}>
          <Routes>
            <Route path='/' exect element={ <Overview  mode={this.state.mode} setNavIndex={this.setNavIndex}/>} />
            <Route path='/Power' element={ <Power mode={this.state.mode} setNavIndex={this.setNavIndex} drawerOpen = {this.open}/> } />
            <Route path='/Water' element={ <Water mode={this.state.mode} setNavIndex={this.setNavIndex} drawerOpen = {this.open}/> } />
            {/* <Route path='/HVAC' element={ <HVAC mode={this.state.mode} setNavIndex={this.setNavIndex}/> } /> */}
            {/* <Route path='/Gases' element={ <Gases setNavIndex={this.setNavIndex}/> } /> */}
            {/* <Route path='/Costing' element={ <Costing setNavIndex={this.setNavIndex}/> } /> */}
            <Route path='/Analytics' element={ <Analytics setNavIndex={this.setNavIndex}/> } />
            <Route path='/Alerts' element={ <AlertsPage setNavIndex={this.setNavIndex}/> } />
            <Route path='/Config' element={ <Config setNavIndex={this.setNavIndex} open = {this.open}/> } />
            <Route path='/Help' element={ <Help setNavIndex={this.setNavIndex}/> } />
            <Route path='/About' element={ <About setNavIndex={this.setNavIndex}/> } />
          </Routes>
        </Box>
      </ThemeProvider>
      </ColorModeContext.Provider>
    </React.Fragment>
  )};
}
export default appMain;
