import React, { useContext } from 'react';
import { Box, Card, Typography, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Divider, Skeleton } from '@mui/material';
import WarningIcon from '@mui/icons-material/Warning';
import WaterIcon from '@mui/icons-material/Water';
import ElectricalServicesIcon from '@mui/icons-material/ElectricalServices';
import { AlertsContext } from '../../context/AlertsContext';
import { ClientsContext } from '../../context/ClientsContext';

// Utility function to format Unix timestamp into date and time
const formatUnixTimestamp = (timestamp) => {
    const date = new Date(timestamp * 1000); // Convert seconds to milliseconds
    const formattedDate = date.toLocaleDateString(undefined, { year: 'numeric', month: 'short', day: 'numeric' });
    const formattedTime = date.toLocaleTimeString(undefined, { hour: '2-digit', minute: '2-digit', hour12: true });
    return { formattedDate, formattedTime };
};

// Utility function to parse and bold text between double asterisks (**)
const parseBoldText = (text) => {
    const parts = text.split(/(\*\*.*?\*\*)/); // Split by double asterisks
    return parts.map((part, index) => {
        if (part.startsWith('**') && part.endsWith('**')) {
            return (
                <Typography key={index} component="span" sx={{ fontWeight: 'bold' }}>
                    {part.replace(/\*\*/g, '')}
                </Typography>
            );
        }
        return <Typography key={index} component="span">{part}</Typography>;
    });
};

// Utility function to get Client Label
const getClientLabel = (deviceId, deviceType, allClients) => {
    let searchId = deviceId;

    if (deviceType === 'water') {
        // For water devices, search directly
        const client = allClients.find(client => client.client_id === searchId);
        return client ? client.client_label : `Unknown Client (${deviceId})`;
    } else if (deviceType === 'electrical') {
        // Remove _phase_r, _phase_y, or _phase_b
        searchId = searchId.replace(/_phase_[rby]$/, '');
        const client = allClients.find(client => client.client_id === searchId);
        return client ? client.client_label : `Unknown Client (${deviceId})`;
    }

    return `Unknown Client (${deviceId})`;
};

const SystemAlerts = () => {
    const { alerts, loading, error } = useContext(AlertsContext);
    const { clients: allClients } = useContext(ClientsContext);
    // Display only the latest 8 alerts
    const latestAlerts = alerts.slice(0, 7);

    // Select icons based on device type
    const iconSelector = (deviceType) => {
        switch (deviceType) {
            case 'electrical': return <ElectricalServicesIcon color="primary" />;
            case 'water': return <WaterIcon color="primary" />;
            default: return <WarningIcon color="action" />;
        }
    };

    // Select colors based on severity
    const severityColor = (severity) => {
        switch (severity.toLowerCase()) {
            case 'low': return 'success.main';
            case 'medium': return 'warning.main';
            case 'high': return 'error.main';
            case 'critical': return 'error.dark';
            default: return 'text.primary';
        }
    };

    return (
        <Box sx={{ flex: 5 }}>
            {loading ? (
                <Skeleton
                    variant="rectangular"
                    animation="wave"
                    width="100%"
                    height="100%"
                    sx={{ borderRadius: 2 }}
                />
            ) : error ? (
                <Typography color="error">Failed to load alerts: {error.message}</Typography>
            ) : (
                <Card sx={{ px: 1, borderRadius: 2, height: '680px', pt: 1 }} variant='outlined'>
                    {/* Fixed System Alerts Header */}
                    <Box sx={{ px: '10px', pt: "5px", justifyContent: "center", display: "flex", position: 'sticky', top: 0, zIndex: 2 }}>
                        <Typography variant="h6" color="textPrimary" sx={{ mb: 1, fontWeight: 600, lineHeight: "30px" }}>
                            System Alerts
                        </Typography>
                        <Divider />
                    </Box>

                    {/* Table Header - Fixed */}
                    <Box sx={{ overflow: 'hidden' }}>
                        <TableContainer>
                            <Table aria-label="alert table">
                                <TableHead sx={{ position: 'sticky', top: 48, zIndex: 1 }}>
                                    <TableRow>
                                        <TableCell align="center" sx={{ width: '5%', fontWeight: 600 }}>Icon</TableCell>
                                        <TableCell align="center" sx={{ width: '8%', fontWeight: 600 }}>Severity</TableCell>
                                        <TableCell align="center" sx={{ width: '50%', fontWeight: 600 }}>Alert Type and Location</TableCell>
                                        <TableCell align="center" sx={{ width: '15%', fontWeight: 600 }}>Time</TableCell>
                                        <TableCell align="center" sx={{ width: '12%', fontWeight: 600 }}>Date</TableCell>
                                        <TableCell align="center" sx={{ width: '8%', fontWeight: 600 }}>Status</TableCell>
                                    </TableRow>
                                </TableHead>
                            </Table>
                        </TableContainer>
                    </Box>

                    {/* Table Body - Scrollable */}
                    <TableContainer sx={{ maxHeight: '560px', overflowY: 'hidden' }}>
                        <Table>
                            <TableBody>
                                {latestAlerts.length > 0 ? (
                                    latestAlerts.map((alert) => {
                                        const { formattedDate, formattedTime } = formatUnixTimestamp(alert.timestamp);
                                        const clientLabel = getClientLabel(alert.device_id, alert.device_type, allClients);

                                        return (
                                            <TableRow key={alert.id}>
                                                <TableCell align="center">{iconSelector(alert.device_type)}</TableCell>
                                                <TableCell align="center">
                                                    <Typography sx={{ color: severityColor(alert.severity), fontSize: 14 }}>
                                                        {alert.severity}
                                                    </Typography>
                                                </TableCell>
                                                <TableCell>
                                                    {parseBoldText(`${alert.message} - **${clientLabel}**`)}
                                                </TableCell>
                                                <TableCell align="center">{formattedTime}</TableCell>
                                                <TableCell align="center">{formattedDate}</TableCell>
                                                <TableCell align="center">
                                                    <Typography sx={{ color: alert.alert_status === 'active' ? 'error.main' : 'text.secondary', fontSize: 14 }}>
                                                        {alert.alert_status.charAt(0).toUpperCase() + alert.alert_status.slice(1)}
                                                    </Typography>
                                                </TableCell>
                                            </TableRow>
                                        );
                                    })
                                ) : (
                                    <TableRow>
                                        <TableCell colSpan={6} align="center">
                                            <Typography>No alerts available.</Typography>
                                        </TableCell>
                                    </TableRow>
                                )}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Card>
            )}
        </Box>
    );
};

export default SystemAlerts;
