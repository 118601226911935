import React, { useContext, useEffect, useState } from "react";
import { AlertsContext } from "../../context/AlertsContext";
import AlertFilters from "../../Components/Alerts/AlertFilters";
import AlertTable from "../../Components/Alerts/AlertTable";
import AlertDetails from "./AlertDetails";
import { Box, CircularProgress } from "@mui/material";

const AlertsPage = (props) => {
  const { loading } = useContext(AlertsContext);
  const [selectedAlert, setSelectedAlert] = useState(null);

  useEffect(() => {
    props.setNavIndex(4);
  }, []);

  return (
    <Box m={2}>
      <AlertFilters />
      <AlertTable onAlertClick={(alert) => setSelectedAlert(alert)} />
      {loading && (
        <Box sx={{ display: "flex", justifyContent: "center", my: 2 }}>
          <CircularProgress size={24} sx={{ mx: 1 }} />
          <CircularProgress size={24} sx={{ mx: 1 }} />
          <CircularProgress size={24} sx={{ mx: 1 }} />
        </Box>
      )}
      <AlertDetails alert={selectedAlert} onClose={() => setSelectedAlert(null)} />
    </Box>
  );
};

export default AlertsPage;
