import { Box } from "@mui/system";
import { useEffect } from "react";

export default function Help(props){
    useEffect( () => {
        props.setNavIndex(5);
     }, []);
    return(
    <div>
        <Box sx={{flexGrow: 1, p: 2, width: { sm: `calc(100% - ${340}px)` }, marginLeft:{sm: '300px'}, justifyContent:"center"}}>
        <h2> Help Page is under Development</h2>
        </Box>
    </div>
    )
}