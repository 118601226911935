const baseURL = "https://api.datasailors.io/iot-backend/api/v1/"
const overviewBackendAddress = baseURL + "overviewConsumptionCards/";
const energyAddress = baseURL + "energyHistogram/";
const powerAddress = baseURL + "powerHistogram/data/";
const waterVolume = baseURL + "waterVolumeHistogram/";
const clientsDB = baseURL + "allClients/";
const waterFlow = baseURL + "waterFlowHistogram/";
const drawerWidth = 280;
const closedDrawerWidth = 75;
export { overviewBackendAddress, energyAddress, clientsDB, powerAddress, waterVolume, waterFlow, drawerWidth, closedDrawerWidth, baseURL };
