import * as React from 'react';
import DrawerContent from './DrawerContent';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import { Divider, Drawer } from '@mui/material';
import RenderMobileMenu from './MobileMenu';
import RenderMenu from './RenderMenu';
import TopBar from './TopBar';
import NavDrawer from './NavDrawer';

const drawerWidth = 280;

export default function NavBar(props) {

  const [anchorEl, setAnchorEl] = React.useState(null);
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = React.useState(null);
  const { window } = props;
  const [mobileOpen, setMobileOpen] = React.useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const isMenuOpen = Boolean(anchorEl);
  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);

  const handleProfileMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null);
    setMobileOpen(false);
  };
  
  const handleMenuClose = () => {
    setAnchorEl(null);
    handleMobileMenuClose();
  };

  const handleMobileMenuOpen = (event) => {
    setMobileMoreAnchorEl(event.currentTarget);
  };

  const container = window !== undefined ? () => window().document.body : undefined;

  return (
    <React.Fragment>
    <Box sx={{ flexGrow: 1, transitionDelay: 'all 0.8s ease-out' }}>
      <TopBar 
        mode={props.mode} handleDrawerToggle={handleDrawerToggle} changeTheme={props.changeTheme}
        handleProfileMenuOpen={handleProfileMenuOpen} handleMobileMenuOpen={handleMobileMenuOpen}
        open={props.open} drawerToggle={props.drawerToggle} mobileOpen={mobileOpen}/>

      <RenderMobileMenu
        mode={props.mode} changeTheme={props.changeTheme} handleProfileMenuOpen={handleProfileMenuOpen}
        mobileMoreAnchorEl={mobileMoreAnchorEl} handleMobileMenuClose={handleMobileMenuClose} isMobileMenuOpen={isMobileMenuOpen}/>

      <RenderMenu anchorEl={anchorEl} isMenuOpen={isMenuOpen} handleMenuClose={handleMenuClose} />
      
      <Divider />
    </Box>
    <Box component="nav" sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}> 
    <Drawer container={container} variant="temporary" open={mobileOpen} onClose={handleDrawerToggle} ModalProps={{keepMounted: true,}}
        sx={{
          display: { xs: 'block', sm: 'none' },
          '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth }, 
        }}
        PaperProps={{
          sx: {
            background: props.mode ==="dark" ? "#563c5c" :"#7f61a5",
            alignContent:"center",
          }
        }}
      >
        <Toolbar />
        <DrawerContent navIndex={props.navIndex} closeNav={handleDrawerToggle} mode={props.mode}/>
    </Drawer>
    <NavDrawer navIndex={props.navIndex} open={props.open} mode={props.mode}/>
    <Toolbar/>
  </Box>
  </React.Fragment>
  );
}