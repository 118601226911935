import React, { useState, useEffect } from 'react';
import {
  Box,
  TextField,
  Button,
  Typography,
  Card,
  Grid,
  Switch,
  FormControlLabel,
  Snackbar,
  Alert,
  MenuItem,
} from '@mui/material';
import axios from 'axios';

export default function Config({ open, setNavIndex }) {
  const [formData, setFormData] = useState({
    organization_id: 'iit_jammu',
    organization_label: 'IIT Jammu',
    block_label: '',
    block_id: '',
    panel_label: '',
    panel_id: '',
    client_label: '',
    client_id: '',
    description: '',
    client_type: '',
    is_active: true,
    is_enabled: true,
    is_faulty: false,
    latitude: '',
    longitude: '',
  });

  const [loading, setLoading] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState('success');

  useEffect(() => {
    setNavIndex(5);
  }, [setNavIndex]);

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: type === 'checkbox' ? checked : value,
    }));

    if (name === 'block_label') {
      setFormData((prev) => ({
        ...prev,
        block_id: value.toLowerCase().replace(/\s+/g, '_'),
      }));
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      await axios.post('https://api.datasailors.io/iot-backend/api/v1/allClients/', [formData]);
      setSnackbarMessage('Configuration updated successfully!');
      setSnackbarSeverity('success');
    } catch (error) {
      setSnackbarMessage('Failed to update configuration. Please try again.');
      setSnackbarSeverity('error');
    } finally {
      setLoading(false);
      setSnackbarOpen(true);
    }
  };

  const handleCloseSnackbar = () => {
    setSnackbarOpen(false);
  };

  return (
    // Outer Box to vertically center the content
    <Box sx={{
      minHeight: `calc(100vh - 65px)`,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center'
    }}>
      {/* Inner Box for form layout */}
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: 1,
          padding: 3,
          maxWidth: '900px',
          borderRadius: 4,
          border: 1,
          boxShadow: 3,
          overflowY: 'auto',
        }}
      >
        <Typography variant='h4' fontWeight='600' textAlign='center' mb={2}>
          Add Remote Client
        </Typography>
        <Card variant='outlined' sx={{ padding: 3, borderRadius: 2 }}>
          <form onSubmit={handleSubmit}>
            <Grid container spacing={2}>
              {[
                ['organization_label', 'Organization Label', true],
                ['organization_id', 'Organization ID', true],
                ['block_label', 'Block Label', true],
                ['block_id', 'Block ID', true],
                ['panel_label', 'Panel Label', true],
                ['panel_id', 'Panel ID', true],
                ['client_label', 'Client Label', true],
                ['client_id', 'Client ID', true],
                ['client_type', 'Client Type', true],
                ['description', 'Description', false],
                ['latitude', 'Latitude', false],
                ['longitude', 'Longitude', false],
              ].map(([name, label, required]) => (
                <Grid item xs={12} sm={6} key={name}>
                  <TextField
                    fullWidth
                    label={label}
                    name={name}
                    value={formData[name]}
                    onChange={handleChange}
                    variant='outlined'
                    required={required}
                    select={name === 'block_label' || name === 'client_type'}
                  >
                    {name === 'block_label' && [
                      'North Block',
                      'South Block',
                      'Faculty Housing',
                      'MRS',
                      'ESS-3A',
                    ].map((option) => (
                      <MenuItem key={option} value={option}>
                        {option}
                      </MenuItem>
                    ))}
                    {name === 'client_type' && [
                      'electrical_generation',
                      'electrical_distribution',
                      'water_generation',
                      'water_distribution',
                    ].map((option) => (
                      <MenuItem key={option} value={option}>
                        {option
                          .replace(/_/g, ' ')
                          .replace(/\b\w/g, (char) => char.toUpperCase())}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>
              ))}
              <Grid item xs={12} sm={4}>
                <FormControlLabel
                  control={<Switch checked={formData.is_active} onChange={handleChange} name='is_active' />}
                  label='Is Active'
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <FormControlLabel
                  control={<Switch checked={formData.is_enabled} onChange={handleChange} name='is_enabled' />}
                  label='Is Enabled'
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <FormControlLabel
                  control={<Switch checked={formData.is_faulty} onChange={handleChange} name='is_faulty' />}
                  label='Is Faulty'
                />
              </Grid>
            </Grid>
            <Box mt={3} display='flex' justifyContent='center'>
              <Button
                type='submit'
                variant='contained'
                color='primary'
                disabled={loading}
              >
                {loading ? 'Updating...' : 'Update Configuration'}
              </Button>
            </Box>
          </form>
        </Card>
        <Snackbar
          open={snackbarOpen}
          autoHideDuration={6000}
          onClose={handleCloseSnackbar}
        >
          <Alert onClose={handleCloseSnackbar} severity={snackbarSeverity} sx={{ width: '100%' }}>
            {snackbarMessage}
          </Alert>
        </Snackbar>
      </Box>
    </Box>
  );
}
