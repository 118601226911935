import React from 'react';
import { BarChart, PieChart, ShowChart, TableChart, Bolt, FlashOn, TrendingUp, Speed, AirlineStops, AccessTime, Equalizer } from '@mui/icons-material';

export const electrcialMetrics = [
    { name: 'Power', icon: <Bolt />, label: 'power' },
    { name: 'RMS Voltage', icon: <FlashOn />, label: 'voltage_rms' },
    { name: 'Peak Voltage', icon: <TrendingUp />, label: 'voltage_peak' },
    { name: 'RMS Current', icon: <Speed />, label: 'current_rms' },
    { name: 'Peak Current', icon: <AirlineStops />, label: 'current_peak' },
    { name: 'Frequency', icon: <AccessTime />, label: 'voltage_frequency' },
    { name: 'Power Factor', icon: <Equalizer />, label: 'phase' },
    { name: 'Total Power', icon: <Bolt />, label: 'total_power' },
];

export const waterMetrics = [
    { name: 'Volume Flow', icon: <Bolt />, label: 'volume_flow_rate' },
    { name: 'Mass Flow', icon: <FlashOn />, label: 'mass_flow_rate' },
    { name: 'Flow Speed', icon: <TrendingUp />, label: 'flow_speed' },
];

export const graphTypes = [
    { type: 'bar', name: 'Bar', icon: <BarChart /> },
    { type: 'line', name: 'Line', icon: <ShowChart /> },
    { type: 'pie', name: 'Pie', icon: <PieChart /> },
    { type: 'table', name: 'Table', icon: <TableChart /> },
]
