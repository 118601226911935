import axios from "axios";
import { API_BASE_URL } from "../config/Constants";

// Fetch All Clients API
export const fetchAllClients = async () => {
  try {
    const response = await axios.get(`${API_BASE_URL}allClients/`);
    return response.data;
  } catch (error) {
    console.error("Error fetching clients:", error);
    throw error;
  }
};
