import React, { useContext } from 'react';
import { Box, TextField, MenuItem, Button } from '@mui/material';
import { AlertsContext } from '../../context/AlertsContext';

const AlertFilters = () => {
  const { filters, setFilters } = useContext(AlertsContext);

  const handleFilterChange = (e) => {
    setFilters((prev) => ({ ...prev, [e.target.name]: e.target.value }));
  };

  return (
    <Box sx={{ display: 'flex', gap: 2, mb: 2, flexWrap: 'wrap', alignItems: 'center' }}>
      <TextField label="Device Type" name="device_type" select value={filters.device_type} onChange={handleFilterChange} size="small" sx={{ minWidth: '200px' }}>
        <MenuItem value="">All</MenuItem>
        <MenuItem value="power">Power</MenuItem>
        <MenuItem value="water">Water</MenuItem>
      </TextField>
      <TextField label="Severity" name="severity" select value={filters.severity} onChange={handleFilterChange} size="small" sx={{ minWidth: '200px' }}>
        <MenuItem value="">All</MenuItem>
        <MenuItem value="info">Info</MenuItem>
        <MenuItem value="warning">Warning</MenuItem>
        <MenuItem value="critical">Critical</MenuItem>
      </TextField>
      <TextField label="Alert Type" name="alert_type" select value={filters.alert_type} onChange={handleFilterChange} size="small" sx={{ minWidth: '200px' }}>
        <MenuItem value="">All</MenuItem>
        <MenuItem value="Upper Threshold Crossed">Upper Threshold Crossed</MenuItem>
        <MenuItem value="Lower Threshold Crossed">Lower Threshold Crossed</MenuItem>
      </TextField>
      <TextField label="Time Interval" name="time_interval" select value={filters.time_interval} onChange={handleFilterChange} size="small" sx={{ minWidth: '200px' }}>
        <MenuItem value="">All</MenuItem>
        <MenuItem value="last_24_hours">Last 24 Hours</MenuItem>
        <MenuItem value="last_7_days">Last 7 Days</MenuItem>
      </TextField>
      <Button variant="contained" onClick={() => setFilters({})}>Reset</Button>
    </Box>
  );
};

export default AlertFilters;
