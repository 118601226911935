import * as React from 'react';
import { styled, useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import MuiDrawer from '@mui/material/Drawer';
import DrawerContent from './DrawerContent';
import { Typography } from '@mui/material';
import { LegendToggle } from '@mui/icons-material';

const drawerWidth = 280;

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(7)} + 12px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(8)} + 12px)`,
  },
});

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    boxSizing: 'border-box',
    ...(open && {
      ...openedMixin(theme),
      '& .MuiDrawer-paper': openedMixin(theme),
    }),
    ...(!open && {
      ...closedMixin(theme),
      '& .MuiDrawer-paper': closedMixin(theme),
    }),
  }),
);

export default function NavDrawer(props){
    const theme = useTheme();
    return(
        <Box sx={{display: { xs: 'none', sm: 'block' } }}>
            <Drawer variant="permanent" open={props.open}>
                <DrawerHeader sx={{justifyContent:'center'}}>
                    <LegendToggle/>
                    <Typography variant="h6" component="div" sx={{ fontWeight:'bold', marginLeft:'1rem', color:props.mode==='dark' ? 'white':'#1f1f1f', fontSize:"1.5rem"}}>
                        Open Analytics
                    </Typography>
                </DrawerHeader>
                <DrawerContent navIndex={props.navIndex} open={props.open} mode={props.mode}/>
            </Drawer>
        </Box>
    )
}
