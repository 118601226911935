import React, { useEffect, useState } from "react";
import { initializeApp } from "firebase/app";
import { getAuth, signInWithEmailAndPassword } from "firebase/auth";
import { Box, Button, TextField, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import LoginWithGoogle from "./LoginWithGoogle";
import datasailorsLogo from "../Assets/dataSailors.png";

const firebaseConfig = {
  apiKey: "AIzaSyCPn4Z1hDnLgb-g6R3wAyDBAG7y0nmNY6M",
  authDomain: "funny-analytics.firebaseapp.com",
  projectId: "funny-analytics",
  storageBucket: "funny-analytics.appspot.com",
  messagingSenderId: "1069526677333",
  appId: "1:1069526677333:web:8c48cea98bb030f37ce1dc",
  measurementId: "G-YFCGPR6B8D",
};

initializeApp(firebaseConfig);
const auth = getAuth();

export default function UserAuthentication() {
  const [userEmail, setUserEmail] = useState("");
  const [userPass, setUserPass] = useState("");
  const [errorCode, setErrorCode] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    if (localStorage.getItem("openAnalyticsAccessToken")) {
      navigate("/");
    }
  }, []);

  const userLogIn = async (email, password) => {
    signInWithEmailAndPassword(auth, email, password)
      .then((userCredential) => {
        localStorage.setItem("openAnalyticsAccessToken", userCredential.user.uid);
        navigate("/");
      })
      .catch((error) => {
        setErrorCode(error.message);
      });
  };

  return (
    <Box
      sx={{
        minHeight: "700px",
        height : "100vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        background: "linear-gradient(to bottom right, #0171bc, #62b6cb, #89c2d9)",
        // padding: "1rem",
      }}
    >
      {/* Center Card */}
      <Box
        sx={{
          display: "flex",
          flexDirection: { xs: "column", md: "row" }, // Stack vertically on small screens
          width: { xs: "90vw", sm: "85vw", md: "75vw", lg: "65vw" },
          maxWidth: "1300px",
          height: { xs: "auto", md: "65vh" },
          minHeight : '565px',
          borderRadius: 4,
          boxShadow: 4,
          overflow: "hidden",
          backgroundColor: "#fff",
        }}
      >
        {/* Left Side - Illustration */}
        <Box
          sx={{
            display: { xs: "none", md: "Block" },
            width:  "60%", // Full width on small screens
            height: { xs: "200px", md: "100%" },
            backgroundImage: 'url("/Images/Login_page.jpg")',
            backgroundSize: "cover",
            backgroundPosition: "center",
          }}
        />

        {/* Right Side - Login Form */}
        <Box
          sx={{
            width: { xs: "90%", md: "40%" },
            padding: { xs: 2, md: 4 },
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            overflowY: "auto", // Prevent overflow on smaller screens
          }}
        >
          {/* Logo Section */}
          <Box sx={{ width: "70px", mb: 2 }}>
            <img
              src={datasailorsLogo}
              style={{ width: "100%", objectFit: "contain" }}
            />
          </Box>

          {/* Welcome Back Text */}
          <Typography variant="h5" fontWeight="700" textAlign="center" color="#0171bc">
            Welcome Back!
          </Typography>
          <Typography textAlign="center" mb={1} fontSize="0.9rem">
            Sign in to continue
          </Typography>

          {/* Email Field */}
          <TextField
            label="Email"
            variant="outlined"
            fullWidth
            margin="normal"
            onChange={(e) => setUserEmail(e.target.value)}
          />
          {/* Password Field */}
          <TextField
            label="Password"
            variant="outlined"
            type="password"
            fullWidth
            margin="normal"
            onChange={(e) => setUserPass(e.target.value)}
          />

          {/* Error Message */}
          {errorCode && (
            <Typography color="error" textAlign="center">
              {errorCode}
            </Typography>
          )}

          {/* Remember Me & Forgot Password */}
          <Box display="flex" justifyContent="space-between" alignItems="center" mt={1} sx={{ width: "100%" }}>
            <Box display="flex" alignItems="center">
              <input type="checkbox" id="remember" />
              <label htmlFor="remember" style={{ marginLeft: "5px" }}>Remember me</label>
            </Box>
            <Typography variant="body2" color="#0171bc" sx={{ cursor: "pointer" }}>
              Forgot password?
            </Typography>
          </Box>

          {/* Sign In Button */}
          <Button
            variant="contained"
            fullWidth
            sx={{
              mt: 2,
              bgcolor: "#0171bc",
              color: "#fff",
              "&:hover": { bgcolor: "#015f9b" },
            }}
            onClick={() => userLogIn(userEmail, userPass)}
          >
            Sign In
          </Button>

          <Typography textAlign="center" mt={1} fontSize="0.8rem" fontWeight={600}>
            OR
          </Typography>

          {/* Google Login Button */}
          <Box sx={{ mt: 1, width: "100%" }}>
            <LoginWithGoogle />
          </Box>

          {/* Sign Up Link */}
          <Typography textAlign="center" mt={2} fontSize="0.8rem">
            Don't have an account?{" "}
            <span style={{ color: "#0171bc", cursor: "pointer" }}>Sign up for free!</span>
          </Typography>
        </Box>
      </Box>
    </Box>
  );
}
