import React, { useEffect, useRef, useState } from 'react';
import * as echarts from 'echarts';
import { Box, Card, CardMedia, Skeleton, RadioGroup, FormControlLabel, Radio } from '@mui/material';
import { useTheme } from '@mui/material/styles';

const COLORS = ['#8fbc8f', '#4682b4', '#cd5c5c'];

const SourcesDonut = (props) => {
  const [viewMode, setViewMode] = useState('energy'); // State to toggle between energy and cost
  const chartRef = useRef(null);
  const theme = useTheme(); // Access the current theme

  const formatNumber = (number) => new Intl.NumberFormat('en-IN').format(number);

  const formatedInterval = (interval) => {
    if (interval === 'daily') return 'Daily';
    if (interval === 'monthly') return 'Monthly';
    return 'Weekly';
  };

  const formatData = () => {
    if (!props.sourcesData || props.sourcesData.length === 0) return [];
    if (viewMode === 'energy') {
      return props.sourcesData.map((item) => ({
        name: item.card_label === 'Transformer' ? 'Grid' : item.card_label === 'Diesel Generator' ? 'DG' : item.card_label,
        value: Math.round(item.energy),
      }));
    } else {
      const costMultipliers = {
        "Transformer": 5.5,
        "Solar": 4,
        "Diesel Generator": 25,
      };
      return props.sourcesData.map((item) => ({
        name: item.card_label === 'Transformer' ? 'Grid' : item.card_label === 'Diesel Generator' ? 'DG' : item.card_label,
        value: Math.round(item.energy * (costMultipliers[item.card_label] || 1)),
      }));
    }
  };

  useEffect(() => {
    const renderChart = () => {
      if (!chartRef.current || !props.sourcesData || props.sourcesData.length === 0) {
        return;
      }

      // Dispose of any existing chart instance to avoid duplicate initialization
      if (echarts.getInstanceByDom(chartRef.current)) {
        echarts.dispose(chartRef.current);
      }

      const myChart = echarts.init(chartRef.current);
      const formattedData = formatData();

      const option = {
        title: {
          text: `${formatedInterval(props.timeInterval)} Sources ${viewMode === 'energy' ? 'Consumption' : 'Cost'}`,
          left: 'center',
          top: 20,
          textStyle: {
            fontSize: 18,
            fontWeight: '600',
            color: theme.palette.text.primary,
          },
        },
        tooltip: {
          trigger: 'item',
          formatter: (params) =>
            `${params.name}: ${
              viewMode === 'cost' ? `₹${formatNumber(params.value)}` : `${formatNumber(params.value)} kW`
            } (${params.percent}%)`,
          backgroundColor: theme.palette.background.paper,
          borderRadius: 4,
          padding: [10, 15],
          textStyle: {
            color: theme.palette.text.primary,
            fontSize: 14,
          },
        },
        legend: {
          orient: 'horizontal',
          bottom: '5%',
          data: formattedData.map((item) => item.name),
          textStyle: {
            fontSize: 14,
            color: theme.palette.text.primary,
          },
        },
        series: [
          {
            name: 'Sources',
            type: 'pie',
            data: formattedData,
            radius: ['38%', '62%'],
            itemStyle: {
              borderRadius: 8,
              shadowBlur: 10,
              shadowOffsetX: 4,
              shadowOffsetY: 4,
              shadowColor: 'rgba(0, 0, 0, 0.5)',
            },
            label: {
              formatter: '{b}: {d}%',
              position: 'outside',
              fontSize: 14,
              color: theme.palette.text.primary,
              fontWeight: 'bold',
            },
            labelLine: {
              show: true,
              length: 15,
              length2: 20,
              smooth: true,
            },
            emphasis: {
              label: {
                show: true,
                fontSize: 16,
                fontWeight: 'bold',
                color: theme.palette.text.primary,
              },
            },
            avoidLabelOverlap: false,
          },
        ],
        color: COLORS,
      };

      myChart.setOption(option, true);
      myChart.resize();
    };

    renderChart();

    // Dispose of the chart instance on component unmount
    return () => {
      if (chartRef.current) {
        echarts.dispose(chartRef.current);
      }
    };
  }, [props.sourcesData, viewMode, theme]);

  return (
    <Box sx={{ flex: 2 }}>
      {props.loading ? (
        <Skeleton
          variant="rectangular"
          animation="wave"
          width="100%"
          height="450px"
          sx={{ borderRadius: 2 }}
        />
      ) : (
        <Card
          sx={{
            borderRadius: 2,
            justifyContent: 'center',
            p: '5px',
            height: '450px',
            position: 'relative',
          }}
          variant="outlined"
        >
          <CardMedia sx={{ justifyContent: 'center', position: 'relative' }}>
            <div ref={chartRef} style={{ width: '100%', height: '390px' }}></div>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <RadioGroup
                value={viewMode}
                onChange={(e) => setViewMode(e.target.value)}
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  gap: '24px',
                  justifyContent: 'center',
                }}
              >
                <FormControlLabel
                  value="energy"
                  control={
                    <Radio
                      sx={{
                        color: theme.palette.text.primary,
                        '&.Mui-checked': { color: COLORS[1] },
                      }}
                    />
                  }
                  label="Energy"
                  sx={{ fontSize: '14px', color: theme.palette.text.primary, fontWeight: '600' }}
                />
                <FormControlLabel
                  value="cost"
                  control={
                    <Radio
                      sx={{
                        color: theme.palette.text.primary,
                        '&.Mui-checked': { color: COLORS[2] },
                      }}
                    />
                  }
                  label="Cost"
                  sx={{ fontSize: '14px', color: theme.palette.text.primary, fontWeight: '600' }}
                />
              </RadioGroup>
            </Box>
          </CardMedia>
        </Card>
      )}
    </Box>
  );
};

export default SourcesDonut;
