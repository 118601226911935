import axios from "axios";
import { API_BASE_URL } from "../config/Constants";

export const fetchAlerts = async (url = null, params = {}) => {
  try {
    const response = await axios.get(url || `${API_BASE_URL}alerts/`, { params });
    return response.data;
  } catch (error) {
    console.error("Error fetching alerts:", error);
    throw error;
  }
};

export const fetchAlertDetails = async (alertId) => {
  try {
    const response = await axios.get(`${API_BASE_URL}alerts/${alertId}`);
    return response.data;
  } catch (error) {
    console.error(`Error fetching alert details for ID: ${alertId}`, error);
    throw error;
  }
};
