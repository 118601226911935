import React, { createContext, useState, useEffect } from "react";
import { fetchAllClients } from "../api/ClientsAPI";

export const ClientsContext = createContext();

export const ClientsProvider = ({ children }) => {
  const [clients, setClients] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  // Fetch clients when the app initializes
  useEffect(() => {
    const loadClients = async () => {
      setLoading(true);
      try {
        const data = await fetchAllClients();
        setClients(data.results || data); // Adjust based on API response structure
      } catch (err) {
        console.error("Failed to fetch clients:", err);
        setError(err);
      } finally {
        setLoading(false);
      }
    };

    loadClients();
  }, []);

  return (
    <ClientsContext.Provider value={{ clients, loading, error }}>
      {children}
    </ClientsContext.Provider>
  );
};
