import React, { useEffect } from 'react';
import * as echarts from 'echarts';
import { useTheme } from '@mui/material/styles'; // Import useTheme
import chartUnits from '../../config/ChartUnits';

const BarChart = ({ chartData, plotType, graphTitle, legendLabels }) => {
  const theme = useTheme(); // Access MUI theme for dynamic colors

  useEffect(() => {
    /**
     * Map data into ECharts-compatible format and handle null values.
     */
    const mapData = (timestamps, data) => {
      return timestamps
        ?.map((timestamp, index) => {
          const value = data?.[index];
          return value !== null && value !== undefined
            ? { name: timestamp * 1000, value } // Format for bar chart
            : null; // Add null for missing values
        })
        .filter(Boolean); // Remove null entries if you want to skip gaps entirely
    };

    const chartDom = document.getElementById('chart');
    if (!chartDom) {
      console.error('Chart DOM not found!');
      return;
    }

    const myChart = echarts.init(chartDom);

    /**
     * Get unit from chartUnits based on plotType
     */
    const unit = chartUnits[plotType] || '';

    /**
     * Define series dynamically
     */
    const series = legendLabels.map((label, index) => ({
      name: label,
      type: 'bar', // Set type to bar
      data: mapData(chartData.timestamps, chartData[label.toLowerCase().replace(' ', '_')]),
      itemStyle: {
        color: ['#FF6961', '#FFD700', '#AEC6CF', '#FF4500'][index % 4],
      },
    }));

    /**
     * Format timestamp for tooltip
     */
    const formatTooltipTime = (timestamp) => {
      const date = new Date(timestamp);
      const time = date.toLocaleTimeString('en-US', {
        hour: '2-digit',
        minute: '2-digit',
        hour12: true,
      });
      const formattedDate = date.toLocaleDateString('en-US', {
        day: '2-digit',
        month: 'short',
        year: '2-digit',
      });
      return `<b>${formattedDate} ${time}</b>`;
    };

    /**
     * Format timestamp for X-Axis labels
     */
    const formatXAxisTime = (timestamp) => {
      const date = new Date(timestamp);
      const dayMonth = date.toLocaleDateString('en-US', {
        day: '2-digit',
        month: 'short',
      });
      const time = date.toLocaleTimeString('en-US', {
        hour: '2-digit',
        minute: '2-digit',
        hour12: true,
      });
      return `${dayMonth}\n${time}`;
    };

    const option = {
      tooltip: {
        trigger: 'axis',
        formatter: (params) => {
          const timestamp = params[0]?.axisValue || 'Unknown Time';
          const formattedTimestamp = formatTooltipTime(timestamp);

          const details = params.map((item) => {
            const value = item.value;
            const formattedValue = typeof value === 'number' ? value.toFixed(2) : 'N/A';
            return `${item.marker} ${item.seriesName}: ${formattedValue} ${unit}`;
          });

          return `${formattedTimestamp}<br/>${details.join('<br/>')}`;
        },
        backgroundColor: theme.palette.background.paper,
        textStyle: {
          color: theme.palette.text.primary,
        },
      },
      legend: {
        top: 'top',
        left: 'center',
        data: legendLabels,
        textStyle: {
          color: theme.palette.text.secondary,
        },
      },
      xAxis: {
        type: 'category',
        data: chartData.timestamps.map((timestamp) => formatXAxisTime(timestamp * 1000)),
        axisLabel: {
          color: theme.palette.text.primary, // Dynamic color
        },
      },
      yAxis: {
        type: 'value',
        axisLabel: {
          formatter: (value) => `${value.toFixed(0)} ${unit}`,
          color: theme.palette.text.primary, // Dynamic color
        },
      },
      grid: {
        left: '5%',
        right: '5%',
        top: '10%',
        bottom: 40, // Increased bottom margin for space between x-axis and zoom bar
        containLabel: true,
      },
      dataZoom: [
        {
          type: 'slider',
          start: 0,
          end: 100,
          bottom: 5, // Increased spacing between data zoom and x-axis
        },
        {
          type: 'inside',
          start: 0,
          end: 100,
        },
      ],
      series,
    };

    myChart.setOption(option);
    window.addEventListener('resize', myChart.resize);

    return () => {
      myChart.dispose();
      window.removeEventListener('resize', myChart.resize);
    };
  
  }, [chartData, plotType, graphTitle, legendLabels, theme]);

  return <div id="chart" style={{ width: '100%', height: '410px' }}></div>;
};

export default BarChart;
