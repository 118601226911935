import { AcUnit, Analytics, Dashboard, ElectricalServices, ElectricBolt, Help, Info, MonetizationOn, NotificationsActive, Opacity, PropaneTank, Settings } from "@mui/icons-material";
import { List} from "@mui/material";
import React, { useEffect } from "react";
import { Box } from "@mui/system";
import NavListItem from "./NavListItem";


export default function DrawerContent(props){
    const [selectedIndex, setSelectedIndex] = React.useState(props.navIndex);
    
    useEffect(()=>{
      setSelectedIndex(props.navIndex);
    },[props.navIndex]);
    
    return(
        <Box sx={{mt:'15px'}}>
        <List
        disablePadding
        sx={{
            // selected and (selected + hover) states
            '&& .Mui-selected, && .Mui-selected:hover': {
              bgcolor: '#72A0C1',
              fontWeight: 'bold',
              '&, & .MuiListItemIcon-root': {
                color: 'white',
              },
            },
            // hover states
            '& .MuiListItemButton-root:hover': {
              bgcolor: 'lightgray',
              '&, & .MuiListItemIcon-root': {
                color: 'black',
              },
            },
            '& .MuiListItemButton-root':{
              color: props.mode==='dark' ? 'white':'#1f1f1f',
            '&, & .MuiListItemIcon-root': {
                color: props.mode==='dark' ? 'white':'#1f1f1f',
            }}
          }}
        >
          <NavListItem selectedIndex={selectedIndex} link='/' index = {0} closeNav={props.closeNav} text='Overview' icon={<Dashboard />} open={props.open}/>
          <NavListItem selectedIndex={selectedIndex} link='/Power' index = {1} closeNav={props.closeNav} text='Power' icon={<ElectricBolt /> } open={props.open}/>
          <NavListItem selectedIndex={selectedIndex} link='/Water' index = {2} closeNav={props.closeNav} text='Water' icon={<Opacity />} open={props.open}/>
          {/* <NavListItem selectedIndex={selectedIndex} link='/HVAC' index = {3} closeNav={props.closeNav} text='HVAC' icon={<AcUnit />} open={props.open}/> */}
          {/* <NavListItem selectedIndex={selectedIndex} link='#' index = {4} closeNav={props.closeNav} text='Gases' icon={<PropaneTank />} open={props.open}/> */}
          {/* <NavListItem selectedIndex={selectedIndex} link='#' index = {5} closeNav={props.closeNav} text='Costing' icon={<MonetizationOn />} open={props.open}/> */}
          <NavListItem selectedIndex={selectedIndex} link='/Analytics' index = {3} closeNav={props.closeNav} text='Analytics' icon={<Analytics />} open={props.open}/>
          <NavListItem selectedIndex={selectedIndex} link='/Alerts' index = {4} closeNav={props.closeNav} text='Alerts' icon={<NotificationsActive />} open={props.open}/>
          <NavListItem selectedIndex={selectedIndex} link='/Config' index = {5} closeNav={props.closeNav} text='Config' icon={ <Settings />} open={props.open}/>
          <NavListItem selectedIndex={selectedIndex} link='#' index = {6} closeNav={props.closeNav} text='Help' icon={ <Help />} open={props.open}/>
          <NavListItem selectedIndex={selectedIndex} link='#' index = {7} closeNav={props.closeNav} text='About Us' icon={<Info />} open={props.open}/>
        </List>
        </Box>
    )
}
