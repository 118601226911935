import React, { useState, useEffect } from 'react';
import List from '@mui/material/List';
import { Card, Collapse, ListSubheader, ListItemButton, ListItemIcon, ListItemText } from '@mui/material';
import { ExpandLess, ExpandMore, PowerSettingsNew, ViewList } from '@mui/icons-material';

function ClientNav({ groupedClients, setSelectedClient, selectedClient }) {
  const [openBlock, setOpenBlock] = useState(null); // Track only one open block

  /**
   * Toggle a Block
   * - Collapse previously open blocks.
   * - Expand the clicked block.
   */
  const handleToggleBlock = (blockId) => {
    setOpenBlock((prev) => (prev === blockId ? null : blockId));
  };

  /**
   * Select a Client
   */
  const handleSelectClient = (client) => {
    setSelectedClient(client); // Notify parent of selected client
  };

  /**
   * Automatically expand the block containing the selected client
   */
  useEffect(() => {
    if (selectedClient) {
      setOpenBlock(selectedClient.block_id);
    }
  }, [selectedClient]);

  return (
    <Card
      orientation="vertical"
      variant="outlined"
      sx={{ width: '280px', height: "100%", overflow: 'auto', px: 1 }}
    >
      <List
        sx={{ width: '100%' }}
        subheader={
          <ListSubheader component="div" sx={{ fontSize: 20, fontWeight: 600 }}>
            Monitoring Points
          </ListSubheader>
        }
      >
        {/* Render Clients Grouped by Block */}
        {Object.entries(groupedClients).map(([blockId, blockData]) => (
          <React.Fragment key={blockId}>
            {/* Block Header */}
            <ListItemButton onClick={() => handleToggleBlock(blockId)}>
              <ListItemIcon>
                <ViewList />
              </ListItemIcon>
              <ListItemText primary={blockData.block_label} />
              {openBlock === blockId ? <ExpandLess /> : <ExpandMore />}
            </ListItemButton>

            {/* Clients in Block */}
            <Collapse in={openBlock === blockId} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                {blockData.clients.map((client) => (
                  <ListItemButton
                    key={client.id}
                    onClick={() => handleSelectClient(client)}
                    sx={{
                      pl: 4,
                      backgroundColor: selectedClient?.id === client.id ? "#72a0c1" : "transparent",
                      borderRadius: selectedClient?.id === client.id ? "10px" : "0px",
                      "&:hover": { backgroundColor: "#D6EBFF" },
                      transition: "background-color 0.3s, border-radius 0.3s",
                    }}
                  >
                    <ListItemIcon sx={{ pr: '5px', color: "inherit" }}>
                      <PowerSettingsNew />
                    </ListItemIcon>
                    <ListItemText 
                      primary={client.client_label} 
                      secondary={client.panel_label} 
                      primaryTypographyProps={{
                        fontWeight: selectedClient?.id === client.id ? 'bold' : 'normal',
                        color: selectedClient?.id === client.id ? 'white' : 'inherit'
                      }}
                      secondaryTypographyProps={{
                        fontSize: '0.8rem',
                        color: selectedClient?.id === client.id ? 'white' : 'text.secondary'
                      }}
                    />
                  </ListItemButton>
                ))}
              </List>
            </Collapse>
          </React.Fragment>
        ))}
      </List>
    </Card>
  );
}

export default ClientNav;
