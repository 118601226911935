import { ListItemButton, ListItemIcon, ListItemText, ListItem, Tooltip } from "@mui/material";
import React, { useEffect } from "react";
import { Link } from "react-router-dom";

export default function NavListItem(props) {

    const [selectedIndex, setSelectedIndex] = React.useState(props.selectedIndex);

    const handleListItemClick = (event, index) => {
        setSelectedIndex(index);
    };

    useEffect(() => {
        setSelectedIndex(props.selectedIndex)
    }, [props.selectedIndex]);

    return (
        <ListItem sx={{ py: '5px', px:'13px' }} component={Link} to={props.link}>
            <Tooltip title={!props.open ? props.text : ''} placement="right" arrow>
                <ListItemButton
                    selected={selectedIndex === props.index}
                    onClick={(event) => {
                        handleListItemClick(event, props.index);
                        if (props.closeNav) {
                            props.closeNav()
                        }
                    }}
                    sx={{ borderRadius: 3, px: props.open ? '25px':'13px' }}>
                    <ListItemIcon>
                        {props.icon}
                    </ListItemIcon>
                    {props.open && <ListItemText primary={props.text} />}
                </ListItemButton>
            </Tooltip>
        </ListItem>
    )
}
