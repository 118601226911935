import React, { useContext, useEffect, useRef } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Card,
  Typography,
  CircularProgress,
  Box,
} from "@mui/material";
import AlertChip from "./AlertChip";
import { AlertsContext } from "../../context/AlertsContext";
import { ClientsContext } from "../../context/ClientsContext";

/**
 * Utility function to format text and make text between double asterisks bold.
 * @param {string} text
 * @returns {JSX.Element}
 */
const formatTextWithBold = (text) => {
  const regex = /\*\*(.*?)\*\*/g; // Matches text between double asterisks
  const parts = text.split(regex); // Split text by regex

  return parts.map((part, index) =>
    index % 2 === 1 ? (
      <Typography key={index} component="span" sx={{ fontWeight: "bold", display: "inline" }}>
        {part}
      </Typography>
    ) : (
      <Typography key={index} component="span" sx={{ display: "inline" }}>
        {part}
      </Typography>
    )
  );
};

/**
 * Utility function to map device ID to client label.
 * @param {string} deviceId - The device ID from the alert.
 * @param {string} deviceType - The device type (e.g., "water" or "electrical").
 * @param {Array} allClients - List of all available clients.
 * @returns {string} - The client label or a fallback if not found.
 */
const getClientLabel = (deviceId, deviceType, allClients) => {
  let searchId = deviceId;

  if (deviceType === "water") {
    // Direct match for water devices
    const client = allClients.find((client) => client.client_id === searchId);
    return client ? client.client_label : `Unknown Client (${deviceId})`;
  } else if (deviceType === "electrical") {
    // Remove _phase_r, _phase_y, or _phase_b for electrical devices
    searchId = searchId.replace(/_phase_[rby]$/, "");
    const client = allClients.find((client) => client.client_id === searchId);
    return client ? client.client_label : `Unknown Client (${deviceId})`;
  }

  return `Unknown Client (${deviceId})`;
};

/**
 * Utility function to format Unix timestamp into a 12-hour time format with a date.
 * @param {number} timestamp - The Unix timestamp to format.
 * @returns {Object} - Formatted time and date.
 */

const AlertTable = ({ onAlertClick }) => {
  const { alerts, pagination, loading, loadNextAlerts } = useContext(AlertsContext);
  const { clients: allClients } = useContext(ClientsContext);
  const loaderRef = useRef(null);

  /**
   * Infinite Scroll Observer
   */
  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        const target = entries[0];
        if (target.isIntersecting && pagination.next && !loading) {
          loadNextAlerts();
        }
      },
      { threshold: 1.0 }
    );

    const currentLoaderRef = loaderRef.current;
    if (currentLoaderRef) {
      observer.observe(currentLoaderRef);
    }

    return () => {
      if (currentLoaderRef) observer.unobserve(currentLoaderRef);
    };
  }, [pagination.next, loading, loadNextAlerts]);

  return (
    <Card sx={{ borderRadius: 2 }}>
      <TableContainer sx={{ maxHeight: "650px", overflow: "auto" }}>
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              <TableCell>
                <Typography variant="subtitle2" sx={{ fontWeight: "bold" }}>
                  Alert
                </Typography>
              </TableCell>
              <TableCell>
                <Typography variant="subtitle2" sx={{ fontWeight: "bold" }}>
                  Device
                </Typography>
              </TableCell>
              <TableCell>
                <Typography variant="subtitle2" sx={{ fontWeight: "bold" }}>
                  Time & Date
                </Typography>
              </TableCell>
              <TableCell>
                <Typography variant="subtitle2" sx={{ fontWeight: "bold" }}>
                  Severity
                </Typography>
              </TableCell>
              <TableCell>
                <Typography variant="subtitle2" sx={{ fontWeight: "bold" }}>
                  Status
                </Typography>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {alerts.map((alert) => (
              <TableRow
                key={alert.id}
                onClick={() => onAlertClick(alert)}
                sx={{ cursor: "pointer", "&:hover": { backgroundColor: "action.hover" } }}
              >
                <TableCell>{formatTextWithBold(alert.message)}</TableCell>
                <TableCell>{getClientLabel(alert.device_id, alert.device_type, allClients)}</TableCell>
                <TableCell>
                  {new Date(alert.timestamp * 1000).toLocaleString("en-US", {
                    hour: "2-digit",
                    minute: "2-digit",
                    hour12: true,
                  })}
                </TableCell>
                <TableCell>
                  <AlertChip label={alert.severity} type={alert.severity} />
                </TableCell>
                <TableCell>
                  <AlertChip label={alert.alert_status} type={alert.alert_status} />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
        {loading && (
          <Box sx={{ display: "flex", justifyContent: "center", my: 2 }}>
            <CircularProgress size={24} sx={{ mx: 1 }} />
          </Box>
        )}
        <div ref={loaderRef} style={{ height: "20px" }} />
      </TableContainer>
    </Card>
  );
};

export default AlertTable;
