import React from 'react';
import { Box, Button, Card, Divider, Typography } from '@mui/material';
import { graphTypes, electrcialMetrics } from '../../config/PlotConfig'; // Adjust the import path

function ControlOptions(props) {
    const metrics = props.metrices; // Use the imported electrical metrics
    const activeColor = '#72a0c1';
    const inactiveColor = '#ffffff';
    const textColorActive = '#ffffff';
    const textColorInactive = '#606162';
    const borderColor = '#d1d1d1';

    return (
        <Card
            sx={{
                maxWidth: '320px',
                minWidth: '180px',
                p: '10px',
                height: '52%',
            }}
            variant="outlined"
        >
            <Typography component="p" sx={{ fontWeight: 600, fontSize: 20, lineHeight: '50px' }}>
                Control Options
            </Typography>
            <Divider sx={{ my: '5px', borderColor: '#d1d1d1' }} />

            {/* Graph Type Section */}
            {/* <Box sx={{ mb: '20px' }}>
                <Typography sx={{ fontWeight: 'bold', fontSize: '0.875rem', mb: '10px' }}>Graph Type</Typography>
                <Box
                    sx={{
                        display: 'flex',
                        gap: '8px',
                        flexWrap: 'wrap',
                    }}
                >
                    {graphTypes.map((graph, index) => (
                        <Button
                            key={index}
                            variant={props.graphType === graph.type ? 'contained' : 'outlined'}
                            sx={{
                                flex: '1 1 calc(50% - 8px)',
                                minWidth: '130px',
                                minHeight: '30px',
                                textTransform: 'none',
                                fontSize: '0.875rem',
                                backgroundColor: props.graphType === graph.type ? activeColor : inactiveColor,
                                color: props.graphType === graph.type ? textColorActive : textColorInactive,
                                borderColor: borderColor,
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                gap: '8px',
                            }}
                            onClick={() => props.setGraphType(graph.type)}
                        >
                            {graph.icon}
                            {graph.name}
                        </Button>
                    ))}
                </Box>
            </Box> */}

            {/* Metric Selection Section */}
            <Box>
                <Typography sx={{ fontWeight: 'bold', fontSize: '0.875rem', mb: '10px' }}>Select Metric</Typography>
                <Box
                    sx={{
                        display: 'flex',
                        flexWrap: 'wrap',
                        gap: '8px',
                    }}
                >
                    {metrics.map((metric, index) => (
                        <Button
                            key={index}
                            variant={props.plotType === metric.label ? 'contained' : 'outlined'}
                            sx={{
                                flex: '1 1 calc(50% - 8px)',
                                minWidth: '130px',
                                minHeight: '60px',
                                textTransform: 'none',
                                fontSize: '0.875rem',
                                backgroundColor: props.plotType === metric.label ? activeColor : inactiveColor,
                                color: props.plotType === metric.label ? textColorActive : textColorInactive,
                                borderColor: borderColor,
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                gap: '8px',
                            }}
                            onClick={() => props.setPlotType(metric.label)}
                        >
                            {metric.icon}
                            <Typography sx={{ fontSize: '0.875rem', fontWeight: '500' }}>{metric.name}</Typography>
                        </Button>
                    ))}
                </Box>
            </Box>
        </Card>
    );
}

export default ControlOptions;
