import React, { useEffect } from 'react';
import * as echarts from 'echarts';
import { Box, Card, CardContent, Divider, Typography } from '@mui/material';
import { baseURL } from '../../Constants';
import { useTheme } from '@mui/material/styles';

const PowerFigures = () => {
  const theme = useTheme();

  useEffect(() => {
    const fetchPowerData = async () => {
      const url = baseURL + "latestData/";
      const requestBody = [
        { "client_id": "source_panel_1_103_phase_r", "client_type": "electrical_generation" },
        { "client_id": "source_panel_1_103_phase_y", "client_type": "electrical_generation" },
        { "client_id": "source_panel_1_103_phase_b", "client_type": "electrical_generation" },
        { "client_id": "source_panel_1_102_phase_r", "client_type": "electrical_generation" },
        { "client_id": "source_panel_1_102_phase_y", "client_type": "electrical_generation" },
        { "client_id": "source_panel_1_102_phase_b", "client_type": "electrical_generation" },
        { "client_id": "source_panel_1_101_phase_r", "client_type": "electrical_generation" },
        { "client_id": "source_panel_1_101_phase_y", "client_type": "electrical_generation" },
        { "client_id": "source_panel_1_101_phase_b", "client_type": "electrical_generation" }
      ];

      try {
        const response = await fetch(url, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(requestBody)
        });

        const data = await response.json();

        const calculatePower = (ids) => {
          return ids.reduce((sum, id) => {
            const item = data.find(d => d.client_id === id);
            return sum + (item?.data?.power || 0);
          }, 0);
        };

        const transformerPower = calculatePower([
          "source_panel_1_103_phase_r",
          "source_panel_1_103_phase_y",
          "source_panel_1_103_phase_b"
        ]);

        const solarPower = calculatePower([
          "source_panel_1_101_phase_r",
          "source_panel_1_101_phase_y",
          "source_panel_1_101_phase_b"
        ]);

        const dieselGeneratorPower = calculatePower([
          "source_panel_1_102_phase_r",
          "source_panel_1_102_phase_y",
          "source_panel_1_102_phase_b"
        ]);

        const powerSources = [
          { id: 'transformerPower', value: transformerPower, maxValue: 800, name: "Grid", color: "#4682b4" },
          { id: 'solarPower', value: solarPower, maxValue: 480, name: "Solar", color: "#8fbc8f" },
          { id: 'dieselGeneratorPower', value: dieselGeneratorPower, maxValue: 320, name: "Diesel Generator", color: "#cd5c5c" },
        ];

        powerSources.forEach(source => {
          const chartDom = document.getElementById(source.id);
          const myChart = echarts.init(chartDom);

          const option = {
            series: [
              {
                type: 'gauge',
                splitNumber: 8,
                min: 0,
                max: source.maxValue,
                itemStyle: {
                  color: source.color,
                  shadowColor: source.color,
                  shadowBlur: 3,
                  shadowOffsetX: 1,
                  shadowOffsetY: 1
                },
                progress: {
                  show: true,
                  roundCap: true,
                  width: 6,
                },
                pointer: {
                  icon: 'path://M2090.36389,615.30999 L2090.36389,615.30999 C2091.48372,615.30999 2092.40383,616.194028 2092.44859,617.312956 L2096.90698,728.755929 C2097.05155,732.369577 2094.2393,735.416212 2090.62566,735.56078 C2090.53845,735.564269 2090.45117,735.566014 2090.36389,735.566014 L2090.36389,735.566014 C2086.74736,735.566014 2083.81557,732.63423 2083.81557,729.017692 C2083.81557,728.930412 2083.81732,728.84314 2083.82081,728.755929 L2088.2792,617.312956 C2088.32396,616.194028 2089.24407,615.30999 2090.36389,615.30999 Z',
                  length: '60%',
                  width: 7,
                },
                axisLine: {
                  lineStyle: {
                    width: 6,
                  }
                },
                axisTick: {
                  show: false
                },
                splitLine: {
                  distance: 4,
                  length: 4,
                  lineStyle: {
                    width: 2,
                    color: '#999'
                  }
                },
                axisLabel: {
                  distance: 8,
                  color: theme.palette.text.primary,
                  fontSize: 10
                },
                anchor: {
                  show: true,
                  showAbove: true,
                  size: 4,
                  itemStyle: {
                    borderWidth: 2
                  },

                },
                title: {
                  show: false
                },
                detail: {
                  valueAnimation: true,
                  fontSize: 18,
                  offsetCenter: [0, '50%'],
                  formatter: function(value) {
                    return `{value|${value.toFixed(2)} kW}\n{name|${source.name}}`;
                  },
                  rich: {
                    value: {
                      fontSize: 15,
                      color: source.color,
                      lineHeight: 70,
                    },
                    name: {
                      fontSize: 18,
                      color: theme.palette.text.primary,
                      padding: [10, 0],
                      fontWeight: 'bold',
                      lineHeight: 30,
                    }
                  }
                },
                data: [
                  {
                    value: source.value,
                  }
                ]
              }
            ]
          };

          myChart.setOption(option);
        });
      } catch (error) {
        console.error('Error fetching power data:', error);
      }
    };

    fetchPowerData();
  }, [theme]);

  return (
    <Box sx={{ width: { xs: "100%" }, mb: '10px' }}>
      <Card sx={{ display: 'flex', justifyContent: 'space-around', borderRadius: 2, height: '310px', width: "100%" }} variant='outlined'>
        <CardContent sx={{ justifyContent: 'center', display: 'flex', flexDirection: 'column' }}>
          <Typography align="center" variant="h5" marginBottom={1.5} sx={{ fontWeight: '600' }}>Live Power Figures</Typography>
          <Divider />
          <Box sx={{ width: { xs: "100%" }, display: 'flex', justifyContent: 'space-around', mt: '20px' }}>
            <div id="transformerPower" style={{ width: 200, height: 200 }}></div>
            <div id="solarPower" style={{ width: 200, height: 200 }}></div>
            <div id="dieselGeneratorPower" style={{ width: 200, height: 200 }}></div>
          </Box>
        </CardContent>
      </Card>
    </Box>
  );
};

export default PowerFigures;
