import * as React from 'react';
import { useNavigate } from 'react-router-dom';
import { getAuth, signOut } from 'firebase/auth';

import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';

import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import LogoutIcon from '@mui/icons-material/PowerSettingsNew';

export default function RenderMenu(props) {
  const auth = getAuth();
  const navigate = useNavigate();

  const handleLogout = () => {
    signOut(auth)
      .then(() => {
        localStorage.removeItem('openAnalyticsAccessToken');
        navigate('/Login');
      })
      .catch((error) => {
        console.log("Logout Failed");
      });
  };

  return (
    <Menu
      id="primary-search-account-menu"
      anchorEl={props.anchorEl}
      open={props.isMenuOpen}
      onClose={props.handleMenuClose}
      // Position the menu below the Profile button
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      // Optional: You can give the menu a bit of style
      PaperProps={{
        sx: {
          mt: 1, // margin top
          borderRadius: 2,
          minWidth: 150,
        },
      }}
      keepMounted
    >
      <MenuItem onClick={props.handleMenuClose}>
        <ListItemIcon>
          <AccountCircleIcon fontSize="small" />
        </ListItemIcon>
        <ListItemText primary="Profile" />
      </MenuItem>
      <MenuItem onClick={handleLogout}>
        <ListItemIcon>
          <LogoutIcon fontSize="small" />
        </ListItemIcon>
        <ListItemText primary="Logout" />
      </MenuItem>
    </Menu>
  );
}
