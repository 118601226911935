import React, { useEffect, useState, useContext } from 'react';
import Box from '@mui/material/Box';
import ClientNav from '../../Components/ClientMonitor/ClientNav';
import ControlOptions from '../../Components/ClientMonitor/ControlOptions';
import LiveFigures from '../../Components/ClientMonitor/LiveFigures';
import CenterGraph from '../../Components/ClientMonitor/CenterGraph';
import { ClientsContext } from '../../context/ClientsContext';
import { fetchHistogramData } from '../../api/HistogramAPI';
import { useLocation } from 'react-router-dom';
import { waterMetrics } from '../../config/PlotConfig';

function Water(props) {
  const location = useLocation();
  const alertSource = location.state || {};

  const [graphType, setGraphType] = useState('line');
  const [plotType, setPlotType] = useState('volume_flow_rate');
  const [selectedClient, setSelectedClient] = useState(null);
  const [chartData, setChartData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [dateRange, setDateRange] = useState([
    new Date(Date.now() - 7 * 24 * 60 * 60 * 1000),
    new Date(),
  ]);

  const { clients: allClients } = useContext(ClientsContext);

  /**
   * Group Clients by Block ID and Filter for Water Distribution
   */
  const groupedClients = allClients
  .filter((client) => ['water_generation', 'water_distribution'].includes(client.client_type))
    .reduce((groups, client) => {
      if (!groups[client.block_id]) {
        groups[client.block_id] = {
          block_label: client.block_label || client.block_id,
          clients: [],
        };
      }
      groups[client.block_id].clients.push(client);
      return groups;
    }, {});

  const sortedGroups = Object.entries(groupedClients).sort(([a], [b]) => a.localeCompare(b));

  /**
   * Set Default Selected Client
   */
  useEffect(() => {
    if (groupedClients) {
      const defaultClient = Object.values(groupedClients)[0]?.clients[0];
      if (defaultClient) {
        setSelectedClient(defaultClient);
      }
    }
  }, [allClients]);

  /**
   * Fetch Chart Data for Selected Client
   */
  useEffect(() => {
    if (selectedClient) {
      const fetchData = async () => {
        setLoading(true);
        try {
          const fromEpoch = Math.floor(dateRange[0].getTime() / 1000);
          const toEpoch = Math.floor(dateRange[1].getTime() / 1000);

          const clientIds = [selectedClient.client_id];

          const data = await fetchHistogramData({
            clientIds,
            fromEpoch,
            toEpoch,
            origin: 'water_distribution',
            metric: plotType,
          });

          setChartData({
            timestamps: data.timestamps,
            flow_rate: data.values[selectedClient.client_id]?.map((value) => plotType === 'flow_speed' ? value * 3.6 : value * 1000),
          });
        } catch (error) {
          console.error('Error fetching chart data:', error);
        } finally {
          setLoading(false);
        }
      };

      fetchData();
    }
  }, [selectedClient, plotType, dateRange]);

  /**
   * Adjust window height dynamically and initialize state from alerts
   */
  useEffect(() => {
    props.setNavIndex(2); // Assuming water is at index 2
    if (alertSource.dateRange) setDateRange(alertSource.dateRange);
    if (alertSource.selectedClient) setSelectedClient(alertSource.selectedClient);
    if (alertSource.metric) setPlotType(alertSource.metric);
  }, []);

  return (
    <Box sx={{ display: 'flex', p: 1, height: `calc(100vh - 65px)`, overflow: 'hidden', pb: '10px' }}>
      {/* Client Navigation */}
      <ClientNav groupedClients={Object.fromEntries(sortedGroups)} setSelectedClient={setSelectedClient} selectedClient={selectedClient} />
      
      {/* Main Content */}
      <Box component="main" sx={{ flexGrow: 1, px: '5px', height: '100%', minHeight: '600px', overflow: 'scroll' }}>
        <LiveFigures selectedClient={selectedClient} />
        <CenterGraph 
          selectedClient={selectedClient}
          chartData={chartData}
          loading={loading}
          graphType={graphType}
          plotType={plotType}
          setDateRange={setDateRange}
          dateRange={dateRange}
          setGraphType={setGraphType}
          setPlotType={setPlotType}
        />
      </Box>

      {/* Control Options */}
      <ControlOptions graphType={graphType} plotType={plotType} setGraphType={setGraphType} setPlotType={setPlotType} drawerOpen={props.drawerOpen} metrices = {waterMetrics}/>
    </Box>
  );
}

export default Water;
