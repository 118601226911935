import React, { useEffect } from "react";
import { Box, Card, Grid, Typography, Skeleton } from "@mui/material";
import { KeyboardDoubleArrowDown, KeyboardDoubleArrowUp, SolarPower, EvStation, Power, Opacity } from "@mui/icons-material";
import RupeeCoin from "./rupee-coin.svg";

export default function TrendsCard(props) {
  useEffect(() => {}, [props.sourcesData]);

  // Function to format numbers with commas
  const formatNumber = (number) => {
    return new Intl.NumberFormat("en-IN").format(number); // Indian numbering system
  };

  // Calculate cost using multipliers
  const calculateCost = () => {
    if (!props.sourcesData) return 0;
    const multiplier =
      props.sourcesData.card_label === "Transformer" ? 5.5 :
      props.sourcesData.card_label === "Solar" ? 4 :
      props.sourcesData.card_label === "Diesel Generator" ? 25 : 1;

    return formatNumber((props.sourcesData.energy * multiplier).toFixed(2));
  };

  // Determine the icon for each energy source
  const getEnergyIcon = () => {
    switch (props.sourcesData.card_label) {
      case "Transformer":
        return <Power sx={{ fontSize: "1.5rem", mr: 1 }} />;
      case "Solar":
        return <SolarPower sx={{ fontSize: "1.5rem", mr: 1 }} />;
      case "Diesel Generator":
        return <EvStation sx={{ fontSize: "1.5rem", mr: 1 }} />;
      default:
        return <Opacity sx={{ fontSize: "1.5rem", mr: 1 }} />;
    }
  };

  return (
    <Box sx={{ minWidth: { xs: "100%", sm: "48%", md: "20%" }, px: "8px", mb: "16px" }}>
      {/* Skeleton for the entire card when loading */}
      {props.loading ? (
        <Card sx={{ borderRadius: 2, height: "110px", py: "16px" }} variant="outlined" >
          <Skeleton variant="text" width="70%" sx={{ marginLeft: 3, marginTop: 2 }} />
          <Grid container sx={{ padding: "16px" }}>
            <Grid item xs={7}>
              <Skeleton variant="rectangular" width="100%" height={30} />
            </Grid>
            <Grid item xs={5}>
              <Skeleton variant="rectangular" width="100%" height={30} />
            </Grid>
          </Grid>
        </Card>
      ) : (
        <Card sx={{ borderRadius: 2, height: "110px", py: "8px", px: 2 }} variant='outlined'>
          {/* Energy Source Name */}
          <Typography
            align="left"
            variant="h6"
            sx={{ fontWeight: "600", marginBottom: "4px" }}
          >
            {props.sourcesData.card_label}
          </Typography>

          {/* Energy and Cost Details */}
          <Grid container sx={{ alignItems: "center" }}>
            <Grid item xs={6} sx={{ display: "flex", alignItems: "center" }}>
              {/* Icon for Energy */}
              {getEnergyIcon()}
              <Typography variant="subtitle1" sx={{ fontWeight: "600"}}>
                {props.sourcesData &&
                  (props.sourcesData.energy_unit === "MWh"
                    ? formatNumber((props.sourcesData.energy / 1000).toFixed(0))
                    : formatNumber(props.sourcesData.energy.toFixed(0)))}{" "}
                {props.sourcesData && props.sourcesData.energy_unit}
              </Typography>
            </Grid>
            <Grid item xs={6} sx={{ display: "flex", justifyContent: "flex-end", alignItems: "center" }}>
              {/* Icon for Cost */}
              <Box component="img" src={RupeeCoin} sx={{ width: "1.5rem", height: "1.5rem", mr: 1 }} />
              <Typography variant="subtitle1" sx={{ fontWeight: "600", color: "#16a085" }}>
                ₹{calculateCost()}
              </Typography>
            </Grid>
          </Grid>

          {/* Drift Bar and Percentage */}
          <Grid container sx={{ alignItems: "center", marginTop: "8px" }}>
            <Grid item xs={8}>
              <Box
                sx={{
                  width: "100%",
                  height: 6,
                  backgroundColor: "#ecf0f1",
                  borderRadius: 2,
                  overflow: "hidden",
                }}
              >
                <Box
                  sx={{
                    height: "100%",
                    width: `${props.sourcesData ? 100 - Math.abs(props.sourcesData.drift) : 0}%`,
                    backgroundColor: props.sourcesData && props.sourcesData.drift >= 0 ? "#E74C3C" : "#2ECC71",
                    borderRadius: 2,
                  }}
                />
              </Box>
            </Grid>
            <Grid
              item
              xs={4}
              sx={{ display: "flex", justifyContent: "flex-end", alignItems: "center" }}
            >
              <Typography
                variant="body2"
                sx={{
                  color: props.sourcesData && props.sourcesData.drift >= 0 ? "#E74C3C" : "#2ECC71",
                  fontWeight: "600",
                }}
              >
                {props.sourcesData && Math.min(Math.abs(props.sourcesData.drift.toFixed(0)), 100)}%
                {props.sourcesData && props.sourcesData.drift >= 0 ? (
                  <KeyboardDoubleArrowUp
                    sx={{ fontSize: "1rem", color: "#E74C3C", marginLeft: "4px" }}
                  />
                ) : (
                  <KeyboardDoubleArrowDown
                    sx={{ fontSize: "1rem", color: "#2ECC71", marginLeft: "4px" }}
                  />
                )}
              </Typography>
            </Grid>
          </Grid>
        </Card>
      )}
    </Box>
  );
}
