import { Box } from "@mui/system";
import { useEffect } from "react";

export default function Analytics(props){

    useEffect( () => {
        props.setNavIndex(3);
    }, []);

    return(
    <div>
        <Box sx={{flexGrow: 1, p: 2, width: { sm: `calc(100% - ${340}px)` }, marginLeft:{sm: '300px'}, justifyContent:"center"}}>
        <h2> Analytics Page is under Development</h2>
        </Box>
    </div>
    )
}