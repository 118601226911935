import React, { useEffect, useState, useContext } from 'react';
import * as echarts from 'echarts';
import { Box, Card, CardContent, Skeleton } from '@mui/material';
import { fetchHistogramData } from '../../api/HistogramAPI';
import { ClientsContext } from '../../context/ClientsContext';
import { useTheme } from '@mui/material';

const WaterConsumptionChart = (props) => {
  const [chartData, setChartData] = useState(null);
  const [loading, setLoading] = useState(true);
  const { clients } = useContext(ClientsContext);
  const theme = useTheme();

  const chartColors = [
    'rgba(135, 206, 250, 0.7)', // Blue
    'rgba(144, 238, 144, 0.7)', // Green
    'rgba(216, 191, 216, 0.7)', // Purple
    'rgba(250, 128, 114, 0.7)', // Coral
    'rgba(173, 216, 230, 0.7)', // Light Blue
  ];

  const getTimeRange = (interval) => {
    const now = Math.floor(Date.now() / 1000); // Current time in seconds
    let fromEpoch;

    switch (interval) {
      case 'daily':
        fromEpoch = now - 24 * 60 * 60; // Last 24 hours
        break;
      case 'weekly':
        fromEpoch = now - 7 * 24 * 60 * 60; // Last 7 days
        break;
      case 'monthly':
        fromEpoch = now - 30 * 24 * 60 * 60; // Last 30 days
        break;
      default:
        fromEpoch = now - 7 * 24 * 60 * 60; // Default to weekly
    }

    return { fromEpoch, toEpoch: now };
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const waterGenerationClients = clients
          ?.filter((client) => client.client_type === 'water_generation')
          ?.map((client) => ({
            id: client.client_id,
            label: client.client_label,
          }));

        if (!waterGenerationClients || waterGenerationClients.length === 0) {
          console.error('No water generation clients found.');
          setChartData(null);
          setLoading(false);
          return;
        }

        const { fromEpoch, toEpoch } = getTimeRange(props.timeInterval);
        const origin = 'water_generation';
        const metric = 'volume_flow_rate';

        const response = await fetchHistogramData({
          clientIds: waterGenerationClients.map((client) => client.id),
          fromEpoch,
          toEpoch,
          origin,
          metric,
        });

        const valuesWithLabels = {};
        waterGenerationClients.forEach((client) => {
          if (response.values[client.id]) {
            valuesWithLabels[client.label] = response.values[client.id];
          }
        });

        setChartData({
          ...response,
          values: valuesWithLabels,
        });
      } catch (error) {
        console.error('Error fetching water consumption data:', error);
        setChartData(null);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [clients, props.timeInterval]);

  useEffect(() => {
    if (!loading && chartData) {
      const chartDom = document.getElementById('waterConsumptionChart');
      const myChart = echarts.init(chartDom);

      const { timestamps, values } = chartData;

      // Check if all data values are zero
      const isAllZero = Object.values(values).every((series) =>
        series.every((val) => val === 0)
      );

      const seriesData = Object.keys(values).map((label, index) => ({
        name: label,
        type: 'line',
        stack: 'Total',
        smooth: true,
        areaStyle: {
          color: chartColors[index % chartColors.length],
        },
        lineStyle: {
          width: 2,
          color: chartColors[index % chartColors.length],
        },
        emphasis: {
          focus: 'series',
        },
        data: values[label].map((val) => val * 60000),
      }));

      const option = {
        title: {
          text: 'Water Flow',
          left: 'center',
          textStyle: {
            fontSize: 16,
            fontWeight: "bold",
            color: theme.palette.text.primary,
          }
        },
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'shadow',
          },
          formatter: (params) => {
            const time = `<b>${params[0].axisValue}</b>`;
            const dataPoints = params
              .map((p) => `${p.seriesName}: ${p.value.toLocaleString()} l/m`)
              .join('<br/>');
            return `${time}<br/>${dataPoints}`;
          },
          textStyle: {
            fontSize: 14,
            color: theme.palette.text.primary,
          },
          backgroundColor: theme.palette.background.paper,
        },
        legend: {
          top: '10%',
          data: Object.keys(values),
          textStyle: {
            fontSize: 12,
            color: theme.palette.text.primary,
          }
        },
        grid: {
          left: isAllZero ? '0%' : '3%', // Remove grid when data is zero
          right: isAllZero ? '0%' : '4%',
          bottom: isAllZero ? '0%' : '3%',
          containLabel: !isAllZero, // Avoid grid overlap
        },
        xAxis: [
          {
            type: 'category',
            boundaryGap: false,
            data: timestamps.map((ts) => new Date(ts * 1000).toLocaleString()),
            show: !isAllZero, // Hide X-axis when data is zero
            axisLabel: {
              color: theme.palette.text.primary,
            },
          },
        ],
        yAxis: [
          {
            type: 'value',
            axisLabel: {
              formatter: '{value} l/m',
              color: theme.palette.text.primary,
            },
            show: !isAllZero, // Hide Y-axis when data is zero
          },
        ],
        series: isAllZero ? [] : seriesData, // Remove series when data is zero
      };

      myChart.setOption(option);

      setTimeout(() => {
        myChart.resize();
      }, 100);
    }
  }, [loading, chartData, theme]);

  return (
    <Box sx={{ mb: '5px', flex: 3 }}>
      {loading ? (
        <Skeleton
          variant="rectangular"
          animation="wave"
          width="100%"
          height="450px"
          sx={{ borderRadius: 2 }}
        />
      ) : (
        <Card
          sx={{
            borderRadius: 2,
            justifyContent: 'center',
            width: '100%',
            pb: '5px',
            height: '450px',
          }}
          variant="outlined"
        >
          <CardContent>
            <div id="waterConsumptionChart" style={{ width: '100%', height: '400px' }}></div>
          </CardContent>
        </Card>
      )}
    </Box>
  );
};

export default WaterConsumptionChart;
