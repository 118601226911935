import React, { useContext } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Typography,
  Box,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { ClientsContext } from "../../context/ClientsContext";

/**
 * Utility to parse text and make parts between double asterisks bold.
 */
const formatTextWithBold = (text) => {
  const regex = /\*\*(.*?)\*\*/g;
  return text.split(regex).map((part, index) =>
    index % 2 === 1 ? (
      <Typography key={index} component="span" sx={{ fontWeight: "bold" }}>
        {part}
      </Typography>
    ) : (
      <Typography key={index} component="span">
        {part}
      </Typography>
    )
  );
};

/**
 * Get client details based on device ID and type.
 */
const getClientDetails = (deviceId, deviceType, allClients) => {
  let searchId = deviceId;

  if (deviceType === "water") {
    return allClients.find((client) => client.client_id === searchId) || null;
  } else if (deviceType === "electrical") {
    searchId = searchId.replace(/_phase_[rby]$/, "");
    return allClients.find((client) => client.client_id === searchId) || null;
  }

  return null;
};

/**
 * Format Unix timestamp into a readable date and time.
 */
const formatTimeAndDate = (timestamp) => {
  const date = new Date(timestamp * 1000);
  const formattedTime = date.toLocaleTimeString("en-US", {
    hour: "2-digit",
    minute: "2-digit",
    hour12: true,
  });
  const formattedDate = date.toLocaleDateString("en-US", {
    day: "2-digit",
    month: "short",
    year: "numeric",
  });
  return { formattedTime, formattedDate };
};

const AlertDetails = ({ alert, onClose }) => {
  const { clients: allClients } = useContext(ClientsContext);
  const navigate = useNavigate();

  if (!alert) return null;

  const clientDetails = getClientDetails(alert.device_id, alert.device_type, allClients);
  const { formattedTime, formattedDate } = formatTimeAndDate(alert.timestamp);

  const handleMonitorSource = () => {
    if (clientDetails) {
      const startTime = new Date(alert.timestamp * 1000 - 60 * 60 * 1000); // 60 minutes before alert
      const endTime = new Date(alert.timestamp * 1000 + 60 * 60 * 1000); // 60 minutes after alert

      navigate(alert.device_type == "water" ? "/Water" :"/Power", {
        state: {
          selectedClient: clientDetails,
          dateRange: [startTime, endTime],
          metric: alert.field_name
        },
      });
    }
  };

  return (
    <Dialog open={Boolean(alert)} onClose={onClose} maxWidth="sm" fullWidth>
      <DialogTitle>Alert Details</DialogTitle>
      <DialogContent>
        <Box sx={{ mb: 2 }}>
          <Typography>
            <b>Client:</b>{" "}
            {clientDetails ? (
              <Typography component="span">{clientDetails.client_label}</Typography>
            ) : (
              <Typography component="span" sx={{ color: "error.main" }}>
                Unknown Client
              </Typography>
            )}
          </Typography>
          <Typography>
            <b>Value:</b> {alert.value}
          </Typography>
          <Typography>
            <b>Threshold:</b> {alert.threshold_value}
          </Typography>
          <Typography>
            <b>Message:</b> {formatTextWithBold(alert.message)}
          </Typography>
          <Typography>
            <b>Status:</b>{" "}
            <Typography
              component="span"
              sx={{
                fontWeight: "bold",
                color: alert.alert_status === "active" ? "error.main" : "success.main",
              }}
            >
              {alert.alert_status.charAt(0).toUpperCase() + alert.alert_status.slice(1)}
            </Typography>
          </Typography>
          <Typography>
            <b>Time:</b> {formattedTime}
          </Typography>
          <Typography>
            <b>Date:</b> {formattedDate}
          </Typography>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" onClick={handleMonitorSource}>
          Monitor Source
        </Button>
        <Button variant="contained" onClick={onClose}>
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AlertDetails;
