import * as React from 'react';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Badge from '@mui/material/Badge';
import MenuIcon from '@mui/icons-material/Menu';
import AccountCircle from '@mui/icons-material/AccountCircle';
import NotificationsIcon from '@mui/icons-material/Notifications'; // or any "Alerts" icon
import MoreIcon from '@mui/icons-material/MoreVert';
import LogoutIcon from '@mui/icons-material/PowerSettingsNew'; // Icon for logout
import { Divider, Typography, Popover } from '@mui/material';
import { Brightness4, Brightness7 } from '@mui/icons-material';
import { styled } from '@mui/material/styles';
import MuiAppBar from '@mui/material/AppBar';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import AlertsPopOver from '../Alerts/alertsPopover';

const drawerWidth = 280;

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open, mode }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  backgroundColor:
    mode === 'light' ? 'whitesmoke' : theme.palette.background.default,
  boxShadow: 'none',
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

export default function TopBar(props) {
  // Popover anchor state for alerts
  const [alertsAnchorEl, setAlertsAnchorEl] = React.useState(null);
  const openAlerts = Boolean(alertsAnchorEl);

  // Handle opening popover
  const handleAlertsOpen = (event) => {
    setAlertsAnchorEl(event.currentTarget);
  };

  // Handle closing popover
  const handleAlertsClose = () => {
    setAlertsAnchorEl(null);
  };

  return (
    <AppBar position="fixed" open={props.open} mode={props.mode}>
      <Toolbar>
        {/* Drawer Toggle Button (Desktop) */}
        <IconButton
          onClick={props.drawerToggle}
          sx={{ display: { xs: 'none', md: 'flex' } }}
          edge="start"
        >
          {props.open ? <ChevronLeftIcon /> : <MenuIcon />}
        </IconButton>

        {/* Drawer Toggle Button (Mobile) */}
        <IconButton
          onClick={props.handleDrawerToggle}
          sx={{ display: { xs: 'flex', md: 'none' } }}
          edge="start"
        >
          {props.open ? <ChevronLeftIcon /> : <MenuIcon />}
        </IconButton>

        {/* Title (hidden if drawer is open on medium+ screens) */}
        <Box sx={{ display: { xs: 'none', md: props.open ? 'none' : 'flex' } }}>
          <Typography
            variant="h6"
            component="div"
            sx={{
              fontWeight: 'bold',
              marginLeft: '1rem',
              color: props.mode === 'dark' ? 'white' : '#1f1f1f',
              fontSize: '1.5rem',
            }}
          >
            Open Analytics
          </Typography>
        </Box>

        {/* Title (mobile) */}
        <Box
          sx={{
            display: { xs: 'flex', md: 'none' },
            alignItems: 'center',
            justifyContent: 'center',
            width: '100%',
          }}
        >
          <Typography
            variant="h6"
            component="div"
            sx={{
              fontWeight: 'bold',
              marginLeft: '1rem',
              color: props.mode === 'dark' ? 'white' : '#1f1f1f',
              fontSize: '1.5rem',
            }}
          >
            Open Analytics
          </Typography>
        </Box>

        {/* Push items to the right */}
        <Box sx={{ flexGrow: 1 }} />

        {/* Icons - Desktop */}
        <Box sx={{ display: { xs: 'none', md: 'flex' } }}>
          {/* Theme Toggle */}
          <IconButton size="large" color="default" onClick={props.changeTheme}>
            {props.mode === 'dark' ? <Brightness7 /> : <Brightness4 />}
          </IconButton>

          {/* Alerts Icon */}
          <IconButton
            size="large"
            color="default"
            onClick={handleAlertsOpen}
            sx={{ ml: 1 }}
          >
            <Badge badgeContent={3} color="error">
              <NotificationsIcon />
            </Badge>
          </IconButton>

          {/* Profile Icon */}
          <IconButton
            size="large"
            color="default"
            sx={{ ml: 1 }}
            onClick={props.handleProfileMenuOpen}
          >
            <AccountCircle />
          </IconButton>
        </Box>

        {/* Mobile Menu Toggle */}
        <Box sx={{ display: { xs: 'flex', md: 'none' } }}>
          <IconButton size="large" onClick={props.handleMobileMenuOpen}>
            <MoreIcon />
          </IconButton>
        </Box>
      </Toolbar>
      <Divider />

      {/* Popover for Alerts */}
      <Popover
        open={openAlerts}
        anchorEl={alertsAnchorEl}
        onClose={handleAlertsClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        PaperProps={{
          sx: { mt: 1, p: 1, width: 300, borderRadius: 2 },
        }}
      >
        <AlertsPopOver />
      </Popover>
    </AppBar>
  );
}
