import React, { useEffect, useState } from "react";
import { Card, Box, Skeleton, Radio, RadioGroup, FormControlLabel } from "@mui/material";
import * as echarts from "echarts";
import { baseURL } from "../../Constants";
import { useTheme } from "@mui/material";

function LiveFigures({ selectedClient }) {
  const theme = useTheme();
  const [liveData, setLiveData] = useState({});
  const [loading, setLoading] = useState(true);
  const [selectedPhase, setSelectedPhase] = useState("phase_r");
  const url = baseURL + "latestData/";

  const isPowerClient =
    selectedClient && (selectedClient.client_type === "electrical_generation" || selectedClient.client_type === "electrical_distribution");

  const createGaugeOption = (title, value, unit, color, maxValue) => ({
    title: {
      text: title,
      left: "center",
      top: "0px",
      textStyle: {
        fontSize: 16,
        fontWeight: "bold",
        color: theme.palette.text.primary,
      },
    },
    series: [
      {
        name: title,
        type: "gauge",
        max: maxValue, // Set the maximum value dynamically
        detail: {
          valueAnimation: true,
          formatter: `{value} ${unit}`,
          offsetCenter: [0, "90%"],
          textStyle: {
            fontSize: 16,
            color: theme.palette.text.primary,
          },
        },
        axisLine: {
          lineStyle: { width: 8, color: [[0.3, color.light], [0.7, color.medium], [1, color.dark]] },
        },
        pointer: {
          width: 5,
        },
        data: [{ value }],
        axisLabel: {
          color: theme.palette.text.primary,
          fontSize: 8,
        },
      },
    ],
  });

  useEffect(() => {
    if (!selectedClient) return;
    const fetchLiveData = async () => {
      setLoading(true);
      try {
        const requestData = isPowerClient
          ? [
              { client_id: `${selectedClient.client_id}_phase_r`, client_type: selectedClient.client_type },
              { client_id: `${selectedClient.client_id}_phase_y`, client_type: selectedClient.client_type },
              { client_id: `${selectedClient.client_id}_phase_b`, client_type: selectedClient.client_type },
            ]
          : [{ client_id: selectedClient.client_id, client_type: selectedClient.client_type }];
        const response = await fetch(url, {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify(requestData),
        });

        const responseData = await response.json();

        if (isPowerClient) {
          const parsedData = {
            phase_r: responseData.find((d) => d.client_id.includes("_phase_r"))?.data || {},
            phase_y: responseData.find((d) => d.client_id.includes("_phase_y"))?.data || {},
            phase_b: responseData.find((d) => d.client_id.includes("_phase_b"))?.data || {},
          };
          setLiveData(parsedData);
        } else {
          const { flow_speed, volume_flow_rate, mass_flow_rate } = responseData[0].data || {};
          setLiveData({
            flow_speed: flow_speed || 0,
            volume_flow_rate: volume_flow_rate || 0,
            mass_flow_rate: mass_flow_rate || 0,
          });
        }
        setLoading(false);
      } catch (error) {
        console.error("Error fetching live figures:", error);
        setLoading(false);
      }
    };

    fetchLiveData();
  }, [selectedClient]);

  useEffect(() => {
    if (!loading) {
      const gaugeData = isPowerClient
        ? [
            {
              id: "voltageGauge",
              title: "Voltage",
              value: liveData[selectedPhase]?.voltage_rms > 500 ? (liveData[selectedPhase]?.voltage_rms/1000).toFixed(2) : liveData[selectedPhase].voltage_rms.toFixed(2) || 0,
              unit: liveData[selectedPhase]?.voltage_rms > 500 ? "kV" : "V",
              maxValue: liveData[selectedPhase]?.voltage_rms > 500 ? 25 : 500,
              color: { light: "#A5D6A7", medium: "#66BB6A", dark: "#2E7D32" },
            },
            {
              id: "currentGauge",
              title: "Current",
              value: liveData[selectedPhase]?.current_rms.toFixed(2) || 0,
              unit: "A",
              maxValue: liveData[selectedPhase]?.current_rms > 500
                ? 500
                : liveData[selectedPhase]?.current_rms > 100
                ? 500
                : 100,
              color: { light: "#FFCC80", medium: "#FFA726", dark: "#E65100" },
            },
            {
              id: "powerGauge",
              title: "Power",
              value: liveData[selectedPhase]?.power.toFixed(2) || 0,
              unit: "kW",
              maxValue: liveData[selectedPhase]?.power > 100 ? 1000 : 100,
              color: { light: "#90CAF9", medium: "#42A5F5", dark: "#1E88E5" },
            },
            {
              id: "phaseGauge",
              title: "Power Factor",
              value: liveData[selectedPhase]?.phase.toFixed(3) || 0,
              unit: "",
              maxValue: 1,
              color: { light: "#FFCDD2", medium: "#E57373", dark: "#C62828" }, // Custom colors for power factor
            },
          ]
        : [
            {
              id: "flowSpeedGauge",
              title: "Flow Speed",
              value: (liveData?.flow_speed * 60).toFixed(2),
              unit: "m/min",
              maxValue: 100, // Placeholder value
              color: { light: "#A5D6A7", medium: "#66BB6A", dark: "#2E7D32" },
            },
            {
              id: "volumeFlowRateGauge",
              title: "Volume Flow Rate",
              value: (liveData?.volume_flow_rate * 60000).toFixed(2),
              unit: "l/min",
              maxValue: 1000, // Placeholder value
              color: { light: "#FFCC80", medium: "#FFA726", dark: "#E65100" },
            },
            {
              id: "massFlowRateGauge",
              title: "Mass Flow Rate",
              value: (liveData?.mass_flow_rate * 60).toFixed(2),
              unit: "g/min",
              maxValue: 1000, // Placeholder value
              color: { light: "#90CAF9", medium: "#42A5F5", dark: "#1E88E5" },
            },
          ];

      gaugeData.forEach(({ id, title, value, unit, color, maxValue }) => {
        const chartDom = document.getElementById(id);
        if (chartDom) {
          const chart = echarts.init(chartDom);
          chart.setOption(createGaugeOption(title, value, unit, color, maxValue));
        }
      });
    }
  }, [loading, liveData, selectedPhase, isPowerClient, theme]);

  return (
    <Card
      sx={{
        height: "33%",
        minHeight: "245px",
        width: "100%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        p: 1,
        mb:'5px'
      }}
      variant="outlined"
    >
      {loading ? (
        <Skeleton variant="rectangular" width="100%" height={240} />
      ) : (
        <>
          {isPowerClient ? (
            <>
              <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                <div id="powerGauge" style={{ width: "24%", height: "200px" }}></div>
                <div id="currentGauge" style={{ width: "24%", height: "200px" }}></div>
                <div id="voltageGauge" style={{ width: "24%", height: "200px" }}></div>
                <div id="phaseGauge" style={{ width: "24%", height: "200px" }}></div>
              </Box>
              <RadioGroup
                row
                value={selectedPhase}
                onChange={(e) => setSelectedPhase(e.target.value)}
                sx={{ justifyContent: "center" }}
              >
                <FormControlLabel
                  value="phase_r"
                  control={<Radio sx={{ color: "#CD5C5C" }} />}
                  label="Phase R"
                />
                <FormControlLabel
                  value="phase_y"
                  control={<Radio sx={{ color: "#FFA726" }} />}
                  label="Phase Y"
                />
                <FormControlLabel
                  value="phase_b"
                  control={<Radio sx={{ color: "#42A5F5" }} />}
                  label="Phase B"
                />
              </RadioGroup>
            </>
          ) : (
            <Box sx={{ display: "flex", justifyContent: "space-between" }}>
              <div id="flowSpeedGauge" style={{ width: "30%", height: "240px" }}></div>
              <div id="volumeFlowRateGauge" style={{ width: "30%", height: "240px" }}></div>
              <div id="massFlowRateGauge" style={{ width: "30%", height: "240px" }}></div>
            </Box>
          )}
        </>
      )}
    </Card>
  );
}

export default LiveFigures;
