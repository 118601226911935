import React from "react";
import { GoogleLogin } from "@react-oauth/google";
import { Box } from "@mui/material";
import { useNavigate } from "react-router-dom";

const LoginWithGoogle = () => {
  const navigate = useNavigate();

  const onSuccess = (response) => {
    const token = response.access_token;
    localStorage.setItem("openAnalyticsAccessToken", token);
    localStorage.setItem("dspl_isGoogleLogin", true);
    navigate("/");
  };

  const onFailure = (response) => console.error("Login Failed:", response);

  return (
    <Box sx={{ width: "100%", display: "flex", justifyContent: "center" }}>
        <GoogleLogin
          onSuccess={onSuccess}
          onError={onFailure}
          ux_mode="popup"
          style={{
            width: "500px",
            display: "block",
            textAlign: "center",
          }}
        />
    </Box>
  );
};

export default LoginWithGoogle;
