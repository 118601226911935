import axios from 'axios';
import { API_BASE_URL } from '../config/Constants';

/**
 * Fetch histogram data for charts.
 * @param {string} powerAddress - API endpoint address
 * @param {Array<string>} clientIds - Array of client IDs
 * @param {number} fromEpoch - Start timestamp (epoch time)
 * @param {number} toEpoch - End timestamp (epoch time)
 * @param {string} origin - Origin type (e.g., 'electrical_distribution', 'water_distribution')
 * @param {string} metric - Metric type (e.g., 'power', 'voltage_rms', etc.)
 * @returns {Promise<object>} - The API response with timestamps and values
 */
export const fetchHistogramData = async ({ clientIds, fromEpoch, toEpoch, origin, metric }) => {
  try {
    const response = await axios.post(`${API_BASE_URL}powerHistogram/data/`, {
      client_id: clientIds,
      from_epoch: fromEpoch,
      to_epoch: toEpoch,
      origin,
      metric,
    });

    return response.data;
  } catch (error) {
    console.error('Error fetching histogram data:', error);
    throw error;
  }
};
