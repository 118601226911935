import React from 'react';
import { Box, Typography, List, ListItem, ListItemText } from '@mui/material';

/**
 * This component represents a dedicated page for alerts.
 * It can also be reused in a popover or modal to display the same alert data.
 */
export default function AlertsPopOver() {
  // Dummy alert data
  const alerts = [
    {
      id: 1,
      title: 'System Maintenance',
      description: 'Scheduled maintenance at 2 AM.',
    },
    {
      id: 2,
      title: 'Server Down',
      description: 'Server #2 is currently down; we are investigating.',
    },
    {
      id: 3,
      title: 'Update Available',
      description: 'A new firmware update is available for installation.',
    },
  ];

  return (
    <Box sx={{ p: 2 }}>
      <Typography variant="h6" gutterBottom>
        Alerts
      </Typography>
      <List sx={{ bgcolor: 'background.paper', borderRadius: 1 }}>
        {alerts.map((alert) => (
          <ListItem
            key={alert.id}
            sx={{
              backgroundColor: '#f5f5f5',
              my: 1,
              borderRadius: 1,
            }}
          >
            <ListItemText
              primary={alert.title}
              secondary={alert.description}
              secondaryTypographyProps={{ color: 'textSecondary' }}
            />
          </ListItem>
        ))}
      </List>
    </Box>
  );
}
